PageApp.Views.SaleInfoView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.saleInfo,
  initialize: function (options) {
    this.options = options
    _.bindAll(this, 'render')
  },
  events: {
    'click .x-toggle': 'handleShowContent'
  },
  serializeData: function () {
    var auctionCollection = this.model.attributes.auctionContent.auctionCollection
    var auctionGoods = this.model.attributes.auctionContent.auctionGoods
    if (auctionGoods) auctionGoods.trim()
    var auctionPayment = this.model.attributes.auctionContent.auctionPayment
    var auctionTerms = this.model.attributes.auctionContent.auctionTerms
    var auctionContacts = this.model.attributes.auctionContent.auctionContacts
    var contentPages = {
      hasAuctionCollection: !!(auctionCollection && auctionCollection.length > 0),
      hasAuctionGoods: !!(auctionGoods && auctionGoods.length > 0),
      hasAuctionPayment: !!(auctionPayment && auctionPayment.length > 0),
      hasAuctionTerms: !!(auctionTerms && auctionTerms.length > 0),
      hasAuctionContacts: !!(auctionContacts && auctionContacts.length > 0),
      auctionCollection: auctionCollection,
      auctionGoods: auctionGoods,
      auctionPayment: auctionPayment,
      auctionTerms: auctionTerms,
      auctionContacts: auctionContacts
    }

    var saleInfo = this.model.attributes
    var hasAddress = (saleInfo.address && saleInfo.address.length > 0)
    var content = {
      JSTextCodes_CLOSE: myApp.reqres.request('i16:getString', 'JSTextCodes_CLOSE'),
      showSaleInfoInPage: this.options.showSaleInfoInPage,
      modal: this.options.modal,
      mapsApiKey: myApp.ent.config.getMapsAPIKey(),
      contentPages: contentPages,
      hasAddress: hasAddress,
      address: saleInfo.address,
      notCatalogue: !saleInfo.catalogue,
      hasClearanceDatesOrComment: (saleInfo.hasClearanceDates || saleInfo.clearanceComment),
      JspPublicCodes_JSP_AUCTION_INFORMATION: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_SITE_ADDRESS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_SITE_ADDRESS'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_SHOW_ON_MAP: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_SHOW_ON_MAP'),
      JspPublicCodes_JSP_LIVE_AUCTION_STARTED: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LIVE_AUCTION_STARTED'),
      JspPublicCodes_JSP_LIVE_AUCTION_STARTS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LIVE_AUCTION_STARTS'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_STARTING_DATE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_STARTING_DATE'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_CLOSING_DATE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_CLOSING_DATE'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_FROM: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_FROM'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_TO: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_TO'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_CURRENCY: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_CURRENCY'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_BUYERS_PREMIUM: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_BUYERS_PREMIUM'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_OR_AS_SPECIFIED: saleInfo.hideTextOrAsSpecified ? '' : myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_OR_AS_SPECIFIED'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_SALES_TAXES: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_SALES_TAXES'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_HAMMER_TAX_RATE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_HAMMER_TAX_RATE'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_BP_TAX_RATE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_BP_TAX_RATE'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_CHARGES_TAX_RATE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_CHARGES_TAX_RATE'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_SALES_TAX_NOT_REQUIRED_MESSAGE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_SALES_TAX_NOT_REQUIRED_MESSAGE'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_VIEWING_DAYS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_VIEWING_DAYS'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_BY_APPOINTMENT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_BY_APPOINTMENT'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_CLEARANCE_DAYS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_CLEARANCE_DAYS'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_COLLECTION: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_COLLECTION'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_TS_AND_CS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_TS_AND_CS'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_PAYMENT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_PAYMENT'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_REMOVAL_OF_GOODS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_REMOVAL_OF_GOODS'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_CONTACTS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_CONTACTS'),
      JspPublicCodes_JSP_AUCTION_INFORMATION_ADDITIONAL_DOCS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_ADDITIONAL_DOCS')
    }
    return _.extend(content, saleInfo)
  },
  handleShowContent: function (e) {
    e.preventDefault()
    var $toggle = this.$el.find('#' + $(e.currentTarget).data('toggle'))
    if ($toggle.hasClass('in')) {
      $toggle.removeClass('in')
    } else {
      $toggle.addClass('in')
    }
  }

})
