PageApp.module('searchComponent', {
  startWithParent: false,
  define: function (searchComponent, MyApp, Backbone, Marionette, $, _) {
    // Public Data And Functions
    // --------------------------

    this.start = function () {
    }

    this.app.vent.on('search:render', function (event) {
      try {
        event.searchInputRegion.show(new PageApp.Views.SearchInputView({ model: event.model }))
      } catch (e) {}
    }, this)

    this.app.vent.on('search:filters', function (event) {
      event.searchFiltersRegion.show(new PageApp.Views.FiltersLayout({ model: event.model }))
    }, this)
  }
})
