PageApp.Controllers.BuyNowRouter = function (config) {
  function initEvents () {
    myApp.vent.on('buynow:display', function (config) {
      if (!this.modalInProgress) {
        this.modalInProgress = true
        var view = new PageApp.Views.BuyNowView({ model: config.model }) // model is a PurchaseEntity
        myApp.myModalRegion.show(new PageApp.Views.ModalLayout({ view: view }))
      }
    }, this)
    myApp.vent.on('modal:closed', function (config) {
      this.modalInProgress = false
    }, this)
  }
  return {
    initialize: function (models) {
      initEvents()
    }
  }
}
