PageApp.Ent.PurchaseEntity = Backbone.Model.extend({
  initialise: function (options) { },
  setModelForBuyNow: function (attributes, screen) {
    var closed = myApp.ent.timedHelper.isClosed(attributes)
    var won = myApp.ent.timedHelper.isWon(attributes)
    var myModel = {
      screen: screen,
      id: attributes.id,
      registrantId: attributes.registrant.id,
      title: attributes.localeSensitive.title,
      buyersPremium: attributes.buyersPremium,
      currencySymbol: attributes.localeSensitive.currency.symbol,
      minimumOfferOrBuyNowPrice: attributes.timedBiddingInfo.minimumOfferOrBuyNowPrice,
      numberOrRef: myApp.ent.timedHelper.getNumberOrRef(attributes),
      closed: closed,
      userStatusMessage: attributes.userStatus.userStatusMessage,
      won: won
    }
    this.set(myModel)
    return this
  },
  setModelForMakeOffer: function (attributes, dialog, serverError) {
    var closed = myApp.ent.timedHelper.isClosed(attributes)
    var won = myApp.ent.timedHelper.isWon(attributes)
    var myHighestOffer = this.getMyHighestOffer(attributes)
    var numberOfOffers = this.getNumberOfOffers(attributes)
    var allowedMoreOffers = !closed && numberOfOffers < attributes.clientOptions.maxOffersPerItem
    if (attributes.itemType.bulkBuyItem) {
      allowedMoreOffers = allowedMoreOffers && attributes.timedBiddingInfo.numberOfUnitsRemaining > 0
    }
    var resultMessage = this.getOfferMessage(attributes, dialog)
    var hasResult, resultClass
    if (resultMessage && resultMessage.length > 0) {
      hasResult = true
      resultClass = 'text-primary'
      if (myHighestOffer) {
        if (myHighestOffer.statusName === 'rejected') {
          resultClass = 'text-danger'
        } else if (myHighestOffer.statusName === 'accepted') {
          resultClass = 'text-success'
        }
      }
    }
    if (serverError) {
      hasResult = true
      resultClass = 'text-danger'
      resultMessage = serverError.message
    }
    if (!this.get('whichAddress')) {
      var whichAddress = 0
    }
    var myModel = {
      id: attributes.id,
      registrantId: attributes.registrant.id,
      title: attributes.localeSensitive.title,
      buyersPremium: attributes.buyersPremium,
      currencySymbol: attributes.localeSensitive.currency.symbol,
      minimumOfferOrBuyNowPrice: attributes.timedBiddingInfo.minimumOfferOrBuyNowPrice,
      numberOrRef: myApp.ent.timedHelper.getNumberOrRef(attributes),
      closed: closed,
      userStatusMessage: attributes.userStatus.userStatusMessage,
      won: won,
      isMultiItem: attributes.itemType.bulkBuyItem,
      allowedMoreOffers: allowedMoreOffers,
      hasOffers: numberOfOffers > 0,
      displayPrice: attributes.timedBiddingInfo.displayPrice,
      numberOfUnits: attributes.timedBiddingInfo.numberOfUnits,
      numberOfUnitsRemaining: attributes.timedBiddingInfo.numberOfUnitsRemaining,
      numberOfOffers: numberOfOffers,
      myHighestOffer: myHighestOffer,
      whichAddress: whichAddress,
      resultMessage: resultMessage,
      hasResult: hasResult,
      resultClass: resultClass
    }
    this.set(myModel)
    return this
  },
  setModelForTransfer: function (attributes, screen) {
    var closed = myApp.ent.timedHelper.isClosed(attributes)
    var won = myApp.ent.timedHelper.isWon(attributes)
    var myModel = {
      screen: screen,
      id: attributes.id,
      registrantId: attributes.registrant.id,
      title: attributes.localeSensitive.title,
      numberOrRef: myApp.ent.timedHelper.getNumberOrRef(attributes),
      closed: closed,
      userStatusMessage: attributes.userStatus.userStatusMessage,
      won: won
    }
    this.set(myModel)
    return this
  },
  setModelForTender: function (attributes, dialog) {
    var closed = myApp.ent.timedHelper.isClosed(attributes)
    var won = myApp.ent.timedHelper.isWon(attributes)

    var numberOfOffers = this.getNumberOfOffers(attributes)
    var allowedMoreOffers = !closed && numberOfOffers < attributes.clientOptions.maxOffersPerItem

    var message = this.getTenderOfferMessage(attributes, dialog)

    var hasMessage = message && message.length > 0

    var myModel = {
      id: attributes.id,
      registrantId: attributes.registrant.id,
      title: attributes.localeSensitive.title,
      buyersPremium: attributes.buyersPremium,
      currencySymbol: attributes.localeSensitive.currency.symbol,
      numberOrRef: myApp.ent.timedHelper.getNumberOrRef(attributes),
      closed: closed,
      userStatusMessage: attributes.userStatus.userStatusMessage,
      won: won,
      allowedMoreOffers: allowedMoreOffers,
      hasOffers: numberOfOffers > 0,
      displayPrice: attributes.timedBiddingInfo.displayPrice,
      numberOfUnits: attributes.timedBiddingInfo.numberOfUnits,
      numberOfOffers: numberOfOffers,
      hasMessage: hasMessage,
      message: message,
      unitOfMeasurement: attributes.timedBiddingInfo.unitOfMeasurement,
      isTenderPct: attributes.timedBiddingInfo.tenderPctEnabled
    }
    this.set(myModel)
    return this
  },
  getTenderOfferMessage: function (attributes, dialog) {
    var numberOfOffers = this.getNumberOfOffers(attributes)
    var message
    if (!dialog && numberOfOffers > 0) {
      message = this.getTenderOfferMessageShort(attributes)
    } else if (numberOfOffers === 1) {
      message = this.getSingleTenderOfferMessage(attributes)
    } else if (numberOfOffers > 1) {
      message = this.getMultipleTenderOfferMessage(attributes)
    }
    return message
  },
  getTenderOfferMessageShort: function (attributes) {
    var message = myApp.reqres.request('i16:getString', 'ItemMessageStatus_TENDER_MESSAGE_SHORT')
    message = message.replace('{0}', this.getNumberOfOffers(attributes))
    message = message.replace('{1}', attributes.clientOptions.maxOffersPerItem)

    return message
  },
  getSingleTenderOfferMessage: function (attributes) {
    var myOffer = attributes.timedUserInfo.offerModels[0]
    var isTenderPct = attributes.timedBiddingInfo.tenderPctEnabled

    var message = myApp.reqres.request('i16:getString', 'ItemMessageStatus_TENDER_MESSAGE')
    if (isTenderPct) {
      message = message.replace('{0}', myApp.utils.types.toFormattedNumber(myOffer.amount))
      message = message.replace('{1}', '%')
    } else {
      message = message.replace('{0}', attributes.localeSensitive.currency.symbol)
      message = message.replace('{1}', myApp.utils.types.toFormattedNumber(myOffer.amount))
    }
    message = message.replace('{2}', myOffer.statusName)
    message = message.replace('{3}', this.getNumberOfOffers(attributes))
    message = message.replace('{4}', attributes.clientOptions.maxOffersPerItem)

    return message
  },
  getMultipleTenderOfferMessage: function (attributes, dialog) {
    var isTenderPct = attributes.timedBiddingInfo.tenderPctEnabled

    var offersString = this.getAllOffersAsString(attributes)
    var per = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_UNIT_OF_MEASUREMENT_PER')
    var unitOfMeasurement = attributes.timedBiddingInfo.unitOfMeasurement
    var message = myApp.reqres.request('i16:getString', 'ItemMessageStatus_MULTIPLE_TENDER_MESSAGE')
    if (isTenderPct) {
      message = message.replace('{0}', unitOfMeasurement)
    } else {
      message = message.replace('{0}', ' ' + per + ' ' + unitOfMeasurement)
    }
    message = message.replace('{1}', offersString)
    message = message.replace('{2}', this.getNumberOfOffers(attributes))
    message = message.replace('{3}', attributes.clientOptions.maxOffersPerItem)

    return message
  },
  getOfferMessage: function (attributes, dialog) {
    var message = false
    var numberOfOffers = this.getNumberOfOffers(attributes)
    var closed = myApp.ent.timedHelper.isClosed(attributes)
    if (dialog) {
      message = this.getOfferMessageForReal(attributes)
    } else if (numberOfOffers > 0 && !closed && myApp.ent.user.isLoggedIn() && attributes.registrant.approved) {
      message = this.getOfferMessageForReal(attributes)
    }
    return message
  },
  getOfferMessageForReal: function (attributes) {
    var myHighestOffer = this.getMyHighestOffer(attributes)
    if (!myHighestOffer) {
      return false
    }
    var message
    if (attributes.itemType.bulkBuyItem) {
      message = myApp.reqres.request('i16:getString', 'ItemMessageStatus_BULK_OFFER_MESSAGE')
      message = message.replace('{0}', attributes.localeSensitive.currency.symbol)
      message = message.replace('{1}', myApp.utils.types.toFormattedNumber(myHighestOffer.amount))
      message = message.replace('{2}', myHighestOffer.numberOfUnitsWanted)
      message = message.replace('{3}', myHighestOffer.statusName)
      message = message.replace('{4}', this.getNumberOfOffers(attributes))
      message = message.replace('{5}', this.getNumberAcceptedOffers(attributes))
    } else {
      message = myApp.reqres.request('i16:getString', 'ItemMessageStatus_OFFER_MESSAGE')
      message = message.replace('{0}', attributes.localeSensitive.currency.symbol)
      message = message.replace('{1}', myApp.utils.types.toFormattedNumber(myHighestOffer.amount))
      message = message.replace('{2}', myHighestOffer.statusName)
      message = message.replace('{3}', this.getNumberOfOffers(attributes))
      message = message.replace('{4}', attributes.clientOptions.maxOffersPerItem)
    }
    return message
  },
  getHighestAcceptedOffer: function (attributes) {
    for (var key in attributes.timedUserInfo.offerModels) {
      var statusName = attributes.timedUserInfo.offerModels[key].statusName
      if (statusName === 'accepted' || statusName === 'transfer') {
        return attributes.timedUserInfo.offerModels[key]
      }
    }
  },
  getNumberAcceptedOffers: function (attributes) {
    var count = 0
    for (var key in attributes.timedUserInfo.offerModels) {
      var statusName = attributes.timedUserInfo.offerModels[key].statusName
      if (statusName === 'accepted' || statusName === 'transfer') {
        count++
      }
    }
    return count
  },
  getNumberOfOffers: function (attributes) {
    if (!attributes.timedUserInfo.offerModels) return 0
    return attributes.timedUserInfo.offerModels.length
  },
  getMyHighestOffer: function (attributes) {
    for (var key in attributes.timedUserInfo.offerModels) {
      if (attributes.timedUserInfo.offerModels[key].userId === myApp.ent.user.get('id')) {
        return attributes.timedUserInfo.offerModels[key]
      }
    }
  },
  getAllOffersAsString: function (attributes) {
    var isTenderPct = attributes.timedBiddingInfo.tenderPctEnabled

    var offersString = ''
    var offers = {}

    var offerStatus = ''
    for (var key in attributes.timedUserInfo.offerModels) {
      offerStatus = attributes.timedUserInfo.offerModels[key].statusName
      var offersWithStatus = offers[offerStatus]
      if (!offersWithStatus) {
        offersWithStatus = ''
        offers[offerStatus] = offersWithStatus
      }
      if (attributes.timedUserInfo.offerModels[key].userId === myApp.ent.user.get('id')) {
        if (offersWithStatus.length > 0) {
          offersWithStatus = offersWithStatus.concat(', ')
        }
        if (isTenderPct) {
          offersWithStatus = offersWithStatus.concat(attributes.timedUserInfo.offerModels[key].amount)
          offersWithStatus = offersWithStatus.concat('%')
        } else {
          offersWithStatus = offersWithStatus.concat(attributes.localeSensitive.currency.symbol)
          offersWithStatus = offersWithStatus.concat(attributes.timedUserInfo.offerModels[key].amount)
        }

        offers[offerStatus] = offersWithStatus
      }
    }

    for (var status in offers) {
      if (offersString.length > 0) {
        offersString = offersString.concat('<br>')
      }
      offersString = offersString.concat(myApp.utils.types.capitalise(status) + ': ')
      offersString = offersString.concat(offers[status])
    }
    return offersString
  },
  getOfferByStatus: function (attributes, status) {
    var offer
    var o
    var haveAcceptedOffer = false
    var havePendingOffer = false
    for (var key in attributes.timedUserInfo.offerModels) {
      o = attributes.timedUserInfo.offerModels[key]
      if (o.statusName === 'accepted') {
        haveAcceptedOffer = true
      } else if (o.statusName === 'pending') {
        havePendingOffer = true
      }
      if (o.statusName === status && (!offer || o.timestamp > offer.timestamp)) {
        offer = o
      }
    }
    // Return the most recent offer of the required status. Although if we have an accepted offer don't return
    // non-accepted offers (except for bulk buy). Or if we have pending don't return rejected.
    // Special case for BulkBuy. Return offer regardless.
    if (offer) {
      if (offer.statusName === 'accepted' || attributes.timedBiddingInfo.ofBulkBuyType) {
        return offer
      } else if (offer.statusName === 'rejected' && !haveAcceptedOffer && !havePendingOffer) {
        return offer
      } else if (offer.statusName === 'pending' && !haveAcceptedOffer) {
        return offer
      }
    }
  }
})
