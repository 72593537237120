PageApp.Views.AddressView = Backbone.Marionette.ItemView.extend({
  templateView: hbs.templates.address,
  templateCreate: hbs.templates.addressCreate,
  getTemplate: function () {
    var editing = this.model.get('editing') || this.model.get('creating')
    var address = this.model.get('address')
    if (address) {
      if (editing) {
        return this.templateCreate
      } else {
        return this.templateView
      }
    } else {
      this.model.set('creating', true)
      return this.templateCreate
    }
  },
  events: {
    'click .x-back': 'handleBack',
    'click .x-edit-address': 'handleEditAddress',
    'click .x-save-address': 'handleSaveAddress'
  },
  serializeData: function () {
    var viewTitle
    var whichAddress = this.model.get('whichAddress')
    if (whichAddress === 0) {
      viewTitle = 'Main Address'
    } else if (whichAddress === 1) {
      viewTitle = 'Shipping Address'
    }
    if (this.model.get('editing')) {
      viewTitle = 'Edit ' + viewTitle
      myApp.vent.trigger('address:render', { 'editing': true })
    } else {
      if (this.model.get('creating')) {
        viewTitle = 'Enter ' + viewTitle
        myApp.vent.trigger('address:render', { 'editing': true })
      } else {
        myApp.vent.trigger('address:render', { 'editing': false })
      }
    }

    if (this.model.get('address') && !this.model.get('address').countryName) {
      var country = myApp.request('reqres:reference:fetch:item', { type: 'country', id: this.model.get('address').countryId })
      if (country) this.model.get('address').countryName = country.name
    }
    var content = {
      viewTitle: viewTitle,
      allowEdit: this.model.get('allowEdit'),
      JSTextCodes_SAVE: myApp.reqres.request('i16:getString', 'JSTextCodes_SAVE'),
      JSTextCodes_BACK: myApp.reqres.request('i16:getString', 'JSTextCodes_BACK'),
      hasError1: (this.model.get('errors').addressLine1) ? 'has-error' : '',
      hasErrorCounty: (this.model.get('errors').county) ? 'has-error' : '',
      hasErrorPostcode: (this.model.get('errors').postcode) ? 'has-error' : ''
    }
    content = _.extend(content, this.model.attributes)
    return content
  },
  onRender: function () {
    if (this.model.get('address')) {
      this.$el.find('.x-country').val(this.model.get('address').countryId)
    } else {
      this.$el.find('.x-country').val('233')
    }
  },
  handleBack: function (e) {
    e.preventDefault()
    this.model.set('editing', false)
    this.render()
  },
  handleEditAddress: function (e) {
    e.preventDefault()
    this.model.set('editing', true)
    this.render()
  },
  handleSaveAddress: function (e) {
    e.preventDefault()
    var address = this.model.get('address') || {}
    if (this.model.get('whichAddress') === 0) {
      address.addressType = 0
    } else if (this.model.get('whichAddress') === 1) {
      address.addressType = 1
    }
    address['@class'] = 'com.bidlogix.scaffold.models.address.AddressModel'
    address.addressLine1 = this.$el.find('#line1').val()
    address.addressLine2 = this.$el.find('#line2').val()
    address.addressLine3 = this.$el.find('#line3').val()
    address.addressLine4 = this.$el.find('#line4').val()
    address.city = this.$el.find('#city').val()
    address.county = this.$el.find('#county').val()
    address.postcode = this.$el.find('#postcode').val()
    address.countryId = this.$el.find('#country').val()
    this.model.set('address', address)
    var result = this.model.validate(this.model.attributes.address)
    if (!result) {
      this.render()
      myApp.vent.trigger('ui:notification', { text: 'Please fix the errors and try again.', level: 'warning' })
      return
    }
    var url = this.model.url()
    myApp.utils.ajax.postPut(address, url, _.bind(function (response) {
      if (response.failed) {
        myApp.vent.trigger('ui:notification', { text: response.message, level: 'warning' })
      } else {
        var whichAddress = this.model.get('whichAddress')
        if (whichAddress === 0) {
          myApp.ent.user.set('hasMainAddress', true)
          myApp.ent.user.set('mainAddress', response)
        } else if (whichAddress === 1) {
          myApp.ent.user.set('hasShippingAddress', true)
          myApp.ent.user.set('shippingAddress', response)
        }
        this.model.set('address', response)
        this.model.set('editing', false)
        this.model.set('creating', false)
        myApp.vent.trigger('ui:notification', { text: 'Your address details have been updated.', level: 'success' })
        this.render()
      }
    }, this))
  }
})
