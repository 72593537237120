PageApp.module('gritterComponent', {
  startWithParent: false,
  define: function (gritterComponent, MyApp, Backbone, Marionette, $, _) {
    // this === gritterComponent; // => true

    // Private Data And Functions
    // --------------------------
    var showNotification = function (gritterOptions) {
      $.gritter.add(gritterOptions)
    }

    // Public Data And Functions
    // --------------------------
    this.app.vent.on('ui:notification', function (options) {
      if (!options || !options.text) {
        return
      }
      if (!options.level) {
        options.class_name = 'alert alert-info'
      } else {
        options.class_name = 'alert alert-' + options.level
      }
      if (!options.time) {
        options.time = 5000
      }
      if (!options.sticky) {
        options.sticky = false
      } else {
        options.sticky = true
      }
      showNotification(options)
    }, this)
  }
})
