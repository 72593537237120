PageApp.Views.MarketplaceInfoView = Backbone.Marionette.ItemView.extend({
  getTemplate: function () {
    return hbs.templates.filterInfo
  },
  serializeData: function () {
    var myModel = this.model.attributes.mpInfoModel
    var hMarketClass
    if (myApp.ent.config.get('page') === 'marketland') {
      hMarketClass = 'g2-market-title-bg'
    }
    var content = {
      hMarketClass: hMarketClass,
      JspPublicCodes_JSP_INFORMATION: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_INFORMATION'),
      mpText: myModel.mpText
    }
    return content
  }
})
