PageApp.Views.MyBidsAlertsFilterView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.myBidsAlertsFilter,
  serializeData: function () {
    var alerts = this.model.get('alerts')
    var showAllItemAlerts = (alerts && alerts.length > 0)

    var currentAlertId = this.model.get('currentAlertId')
    if (currentAlertId) {
      for (var index in alerts) {
        alerts[index].active = (alerts[index].id === currentAlertId) ? 'active' : ''
      }
    }

    var content = {
      contextPath: myApp.ent.config.get('contextPath'),
      showAllItemAlerts: showAllItemAlerts,
      JspPublicCodes_JSP_MY_BIDS_MANAGE_ALERTS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_BIDS_MANAGE_ALERTS'),
      JspPublicCodes_JSP_MY_BIDS_VIEW_ALL_ALERT_MATCHES: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_BIDS_VIEW_ALL_ALERT_MATCHES')
    }

    content = _.extend(content, this.model.attributes)

    return content
  }
})
