PageApp.Views.PagingView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.pagingBootstrap,
  initialize: function (options) {
    this.options = options
  },
  events: {
    'click .x-previous': 'handlePrevious',
    'click .x-next': 'handleNext',
    'click .x-goto': 'handleGoto'
  },
  serializeData: function () {
    var numPages = this.model.get('pagingModel').numPages
    var paginationPosition = this.model.get('pagingModel').paginationPosition
    var pageIndex = this.model.get('pagingModel').pageIndex
    var previous = pageIndex > 0
    var next = pageIndex < (numPages - 1)
    var maxPagesToShow = 3
    var pages = []
    var lastPage = numPages - 1
    var index = pageIndex - (Math.floor(maxPagesToShow / 2))
    var upperBound = pageIndex + Math.floor(maxPagesToShow / 2) + 1

    // adjust bounds
    if (pageIndex >= numPages - (Math.floor(maxPagesToShow / 2))) {
      index = (numPages - maxPagesToShow)
      upperBound = numPages
    }
    if (pageIndex < Math.ceil(maxPagesToShow / 2)) {
      index = 0
      upperBound = maxPagesToShow
    }

    // create pages
    for (index; index < upperBound; index++) {
      if (index < numPages) {
        var link = true
        var id = 'pagination' + index + paginationPosition
        var label = index + 1
        if (index === pageIndex) {
          link = false
        }
        pages.push({ id: id, link: link, gotoIndex: index, label: label })
      }
    }

    var pageOfMsg = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_PAGE_1_OF')
    pageOfMsg = pageOfMsg.replace('{0}', pageIndex + 1)
    pageOfMsg = pageOfMsg.replace('{1}', numPages)

    var content = {
      controller: this.model.get('pagingModel').controller,
      numPages: numPages,
      pages: pages,
      lastPage: lastPage,
      next: next,
      previous: previous,
      pageIndex: pageIndex,
      numberOfItemsPerPage: this.model.get('pagingModel').numberOfItemsPerPage,
      pageXofXMessage: this.model.get('pagingModel').pageXofXMessage,
      paginationPosition: paginationPosition,
      JspPublicCodes_JSP_PAGE_PREVIOUS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_PAGE_PREVIOUS'),
      JspPublicCodes_JSP_PAGE_NEXT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_PAGE_NEXT'),
      pageOfMsg: pageOfMsg
    }
    return content
  },
  handleGoto: function (e) {
    if (e) e.preventDefault()
    var itemId = $(e.currentTarget).data('goto')
    var link = this.model.getLink('page', itemId)
    myApp.utils.ajax.relocate(link)
  },
  handlePrevious: function (e) {
    if (e) e.preventDefault()
    var itemId = this.model.get('pagingModel').pageIndex - 1
    var link = this.model.getLink('page', itemId)
    myApp.utils.ajax.relocate(link)
  },
  handleNext: function (e) {
    if (e) e.preventDefault()
    var itemId = this.model.get('pagingModel').pageIndex + 1
    var link = this.model.getLink('page', itemId)
    myApp.utils.ajax.relocate(link)
  }
})
