PageApp.Views.CurrentFilterView = Backbone.Marionette.ItemView.extend({

  getTemplate: function () {
    return hbs.templates.currentFilter
  },
  initialize: function (options) {
    this.options = options
  },
  events: {
    'click .x-remove': 'handleRemove'
  },
  serializeData: function () {
    var currentViewModel = this.model.attributes.currentFilterModel
    var myLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEMS')

    if (currentViewModel.totalNumberOfMatches === 1) {
      myLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM')
    }
    var content = currentViewModel
    if (myApp.ent.config.get('page') === 'marketland') {
      content.hMarketClass = 'g2-market-title-bg'
    }
    content.numbItemsLabel = myLabel
    try {
      content.showOpenOnly = this.model.get('parameters').parameters.showOpenOnly
    } catch (err) {
      content.showOpenOnly = false
    }
    content.JspPublicCodes_JSP_TIMED_EMPTY_FILTERS = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_TIMED_EMPTY_FILTERS')
    content.JspPublicCodes_JSP_FOUND = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_FOUND')
    content.JspPublicCodes_JSP_CLEAR_ALL_FILTERS = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CLEAR_ALL_FILTERS')
    content.JspPublicCodes_JSP_CATEGORY = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CATEGORY')
    content.JspPublicCodes_JSP_REMOVE_SEARCH_TERM = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_REMOVE_SEARCH_TERM')
    content.JspPublicCodes_JSP_REMOVE_FILTER = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_REMOVE_FILTER')
    return content
  },
  handleRemove: function (e) {
    if (e) e.preventDefault()
    var filterId = $(e.currentTarget).data('filterid')
    var link = this.model.getRemoveLink(filterId)
    myApp.utils.ajax.relocate(link)
  }
})
