PageApp.Views.MyBidsDateFilterView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.myBidsDateFilter,
  serializeData: function () {
    var content = {
      JspPublicCodes_JSP_MY_SALES_SHOW_ITEMS_FROM: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SALES_SHOW_ITEMS_FROM'),
      JspPublicCodes_JSP_MY_SALES_DATE_FILTER_TO: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SALES_DATE_FILTER_TO'),
      JspPublicCodes_JSP_MY_SALES_DATE_FILTER_APPLY: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SALES_DATE_FILTER_APPLY')
    }
    return content
  },
  onRender: function () {
    this.$el.find('.x-datepicker').each(function () {
      var $this = $(this)
      $this.datepicker(myApp.utils.types.datepickerOptions())
      if ('placeholder' in this) { // detect for placeholder support and set it if available
        $this.attr('placeholder', myApp.utils.types.datepickerOptions().placeHolderDateFormat)
      }
    })
    this.$el.find('#startDate').val(this.model.get('startDate'))
    this.$el.find('#endDate').val(this.model.get('endDate'))
  }
})
