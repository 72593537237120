PageApp.Controllers.ReferenceRouter = function (config) {
  var referenceData = new PageApp.Ent.ReferenceData()
  function getContent (types) {
    return new Promise(function (resolve, reject) {
      var neededTypes = referenceData.getKeysNeeded(types)
      if (!neededTypes) {
        resolve(referenceData.getReferenceData(types))
      } else {
        myApp.utils.ajax.get(null, myApp.utils.ajax.getApiEndpoint('reference') + '/' + neededTypes.join(','), function (response) {
          // var refData = response.models.ReferenceDataModel.referenceItemModelMap;
          var refData = response.referenceItemModelMap
          referenceData.setReferenceData(neededTypes.join(','), refData)
          resolve(referenceData.getReferenceData(types))
        })
      }
    })
  }

  function initCommands () {
    myApp.reqres.setHandler('reqres:reference:fetch', function (config) {
      if (!config || !config.types) {
        throw new Error('no type of reference data specified')
      }

      return getContent(config.types)
    })

    myApp.reqres.setHandler('reqres:reference:fetch:item', function (config) {
      // Please note that this call is reliant upon reqres:reference:fetch (above) being called
      // first for the particular type in order to populate referenceData
      if (!config || !config.type) {
        throw new Error('no type of reference data specified')
      }
      if (config.type === 'country') {
        var countries = referenceData.get('countries')
        if (countries) {
          for (var key in countries) {
            if (countries[key].id === config.id) {
              return countries[key]
            }
          }
        }
      }
    })
  }

  return {
    initialize: function (models) {
      initCommands()
    }
  }
}
