PageApp.Views.WaitingView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.waiting,
  initialize: function () {
  },
  serializeData: function () {
    if (!this.model) {
      this.model = new PageApp.Ent.BasicEntity({ align: 'text-center' })
    }
    return this.model.attributes
  }
})
