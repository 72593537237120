PageApp.Controllers.ContactSellerRouter = function (config) {
  function initEvents () {
    myApp.vent.on('contactseller:display', function (config) {
      if (!this.modalInProgress) {
        this.modalInProgress = true
        if (!this.contactSellerModel) {
          this.contactSellerModel = new PageApp.Ent.BasicEntity(config.model)
        }
        if (config.model) {
          this.contactSellerModel.set('id', config.model.id)
          this.contactSellerModel.set('title', config.model.title)
        }
        this.contactSellerModel.set('callbackTrigger', config.callbackTrigger)
        var view = new PageApp.Views.ContactSellerView({ model: this.contactSellerModel })
        myApp.myModalRegion.show(new PageApp.Views.ModalLayout({ view: view }))
      }
    }, this)
    myApp.vent.on('modal:closed', function (config) {
      this.modalInProgress = false
    }, this)
  }
  return {
    initialize: function (models) {
      initEvents()
    }
  }
}
