PageApp.Views.ContinentFilterView = Backbone.Marionette.ItemView.extend({
  getTemplate: function () {
    return hbs.templates.continentFilter
  },
  initialize: function (options) {
    this.options = options
  },
  events: {
    'click .x-continent': 'handleContinent',
    'click .x-country': 'handleCountry'
  },
  serializeData: function () {
    var currentViewModel = this.model.attributes.continentFilterModel
    var currentFilterItems = currentViewModel.filterItems
    var currentSelectedFilterItems = _.filter(currentFilterItems, function (o) { return o.selected === true })
    var hMarketClass
    if (myApp.ent.config.get('page') === 'marketland') {
      hMarketClass = 'g2-market-title-bg'
    }
    var content = {
      baseUrl: this.model.attributes.currentFilterModel.baseUrl,
      hMarketClass: hMarketClass,
      filterHeaderName: currentViewModel.filterTitle,
      filterItems: currentFilterItems,
      queryParameterName: 'continent',
      childQueryParameterName: 'country',
      continentExpanded: currentSelectedFilterItems.length > 1
    }
    return content
  },
  onRender: function () {
    var currentViewModel = this.model.attributes.continentFilterModel
    var currentFilterItems = currentViewModel.filterItems
    var currentSelectedFilterItems = _.filter(currentFilterItems, function (o) { return o.selected === true })
    for (var i = 0; i < currentSelectedFilterItems.length; i++) {
      var selectedFilterItemId = currentSelectedFilterItems[i].item.id
      this.$el.find('#continent-checkbox' + selectedFilterItemId).prop('checked', true)
    }
  },
  handleCountry: function (e) {
    e.preventDefault()
    var itemId = $(e.currentTarget).data('itemid')
    var link = this.model.getLink('country', itemId)
    // myApp.controllers.application.doRoute({'href': link, 'manual': true});
    myApp.utils.ajax.relocate(link)
    return false
  },
  handleContinent: function (e) {
    var itemId = $(e.currentTarget).data('itemid')
    var link = this.model.getLink('continent', itemId)
    myApp.utils.ajax.relocate(link)
  }

})
