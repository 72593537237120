PageApp.Views.CurrentBidView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.currentBid,
  serializeData: function () {
    var bidMessage = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_HIGHEST_BID')
    var priceToDisplay = this.model.attributes.highBidAmountWithCurrency
    var alwaysShowBidMessage = false
    var borderedClass = ''

    if (this.model.get('closed')) {
      if (this.model.attributes.boughtViaBuyNow) {
        bidMessage = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BUY_NOW_BOUGHT')
        priceToDisplay = this.model.attributes.minimumOfferOrBuyNowPrice
      } else {
        bidMessage = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_WINNING_BID')
      }
    }

    var content = {
      bidMessage: bidMessage,
      borderedClass: borderedClass,
      priceToDisplay: priceToDisplay,
      alwaysShowBidMessage: alwaysShowBidMessage,
      displayPriceMessage: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_DISPLAY_PRICE'),
      JspPublicCodes_JSP_BIDDING_BID_INCREMENT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BID_INCREMENT'),
      JspPublicCodes_JSP_MY_SALES_NUM_BIDS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SALES_NUM_BIDS')
    }
    content = _.extend(content, this.model.attributes)
    return content
  }
})
