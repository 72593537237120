PageApp.Views.TransferView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.transfer,
  templateConfirm: hbs.templates.transferConfirm,
  templateWon: hbs.templates.transferWon,
  templateLost: hbs.templates.transferLost,
  getTemplate: function () {
    var screen = this.model.get('screen')
    if (!screen || screen === 1) {
      return this.template
    } else if (this.model.get('screen') === 2) {
      return this.templateConfirm
    } else {
      if (this.model.get('won')) {
        return this.templateWon
      } else {
        return this.templateLost
      }
    }
  },
  modelEvents: {
    'change': 'render'
  },
  events: {
    'click .x-continue': 'handleContinue',
    'click .x-submit': 'handletransfer'
  },
  serializeData: function () {
    var screen = this.model.get('screen')
    var content
    if (!screen || screen === 1) {
      content = {
        JSTextCodes_PROCEED: myApp.reqres.request('i16:getString', 'JSTextCodes_PROCEED'),
        JspPublicCodes_PURCHASE_DIALOG_TRANSFER_TITLE: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_TRANSFER_TITLE'),
        JSTextCodes_CLOSE: myApp.reqres.request('i16:getString', 'JSTextCodes_CLOSE'),
        PageCodes_PAGE_TRANSFER_HELP_TITLE: myApp.reqres.request('i16:getString', 'PageCodes_PAGE_TRANSFER_HELP_TITLE'),
        PageCodes_PAGE_TRANSFER_HELP: myApp.reqres.request('i16:getString', 'PageCodes_PAGE_TRANSFER_HELP')
      }
    } else if (screen === 2) {
      content = {
        title: this.model.attributes.title,
        numberOrRef: this.model.attributes.numberOrRef,
        JspPublicCodes_PURCHASE_DIALOG_PLEASE_PROVIDE_FINAL_CONFIRMATION_TRANSFER: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_PLEASE_PROVIDE_FINAL_CONFIRMATION_TRANSFER'),
        JspPublicCodes_PURCHASE_DIALOG_FINAL_CONFIRMATION_OF_TRANSFER: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_FINAL_CONFIRMATION_OF_TRANSFER'),
        JSTextCodes_SUBMIT: myApp.reqres.request('i16:getString', 'JSTextCodes_SUBMIT'),
        JSTextCodes_CANCEL: myApp.reqres.request('i16:getString', 'JSTextCodes_CANCEL')

      }
    } else {
      if (this.model.get('won')) {
        content = {
          JspPublicCodes_PURCHASE_DIALOG_TRANSFER_SUCCESSFUL: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_TRANSFER_SUCCESSFUL'),
          JspPublicCodes_PURCHASE_DIALOG_TRANSFER_CONGRATULATIONS: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_TRANSFER_CONGRATULATIONS'),
          JSTextCodes_CLOSE: myApp.reqres.request('i16:getString', 'JSTextCodes_CLOSE')
        }
      } else {
        content = {
          JspPublicCodes_PURCHASE_DIALOG_BUY_NOW: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_TRANSFER'),
          JSTextCodes_CLOSE: myApp.reqres.request('i16:getString', 'JSTextCodes_CLOSE')
        }
      }
    }
    content = _.extend(content, this.model.attributes)
    return content
  },
  onRender: function () {
  },
  handleContinue: function (e) {
    e.preventDefault()
    this.model.set('screen', 2)
  },
  handletransfer: function (e) {
    e.preventDefault()
    var bean = {
      '@class': 'com.bidlogix.scaffold.models.bidding.TransferRequestModel',
      itemId: this.model.attributes.id,
      registrantId: this.model.attributes.registrantId,
      userId: myApp.ent.user.get('id')
    }
    myApp.utils.ajax.postBean(bean, myApp.utils.ajax.getApiEndpoint('timed/transfer'), _.bind(function (response) {
      if (response.models.ErrorModel) {
        myApp.ent.user.checkLogout(response)
        myApp.vent.trigger('ui:notification', { text: response.models.ErrorModel.message, level: 'warning' })
      } else {
        var itemModel = myApp.request('reqres:timed:set:timedBiddingInfo', { models: response.models, rabbitCode: response.actionCode })
        myApp.ent.purchaseEntity.setModelForTransfer(itemModel.attributes, 3)
      }
    }, this))
    return false
  }
})
