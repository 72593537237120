PageApp.Ent.LayoutControl = Backbone.Model.extend({
  defaults: {
    showOpenOnly: false
  },
  initialize: function () {
    this.set('findBy', (myApp.ent.webApp.get('defaultGotoLotByTitle') === true) ? 'lotTitle' : 'lotNumber')
  },
  setItemTypeaheadSource: function (items) {
    this.set('items', items)
  },
  getItemTypeaheadSource: function () {
    return this.get('items')
  },
  getAuctionId: function () {
    return (this.get('parameters')) ? this.get('parameters').auctionId : ''
  },
  extractIdForTypeahead: function (type, item) {
    if (type === 'items') {
      var p1 = item.code.split('::')[0]
      return myApp.utils.types.toNumber(p1.split('-')[2])
    } else {
      throw new Error('Unknown type ' + type + ' for type ahead search.')
    }
  },
  initTypeahead: function (type, $input) {
    var $tyelf = this
    var $gobtn = $input.parent().parent().find('.x-ta-go')
    try {
      $input.typeahead({
        autoSelect: true,
        items: 'all',
        minLength: 1,
        source: function (query, process) {
          if ($tyelf.getItemTypeaheadSource()) {
            process($tyelf.getItemTypeaheadSource())
            return
          }
          var url = myApp.utils.ajax.getApiEndpoint('typeahead/item') + '/' + $tyelf.getAuctionId()
          myApp.utils.ajax.get(null, url, function (result) {
            if (!result || result.failed) {
              return []
            } else {
              $tyelf.setItemTypeaheadSource(result)
              process($tyelf.getItemTypeaheadSource())
            }
          })
        },
        matcher: function (item) {
          if ($tyelf.get('findBy') === 'lotNumber') {
            return item.code.toLowerCase().indexOf(this.query.toLowerCase()) > -1
          } else {
            return item.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1
          }
        },
        afterSelect: function (item) {
          if (item) {
            $tyelf.set('currentItem', item)
            $gobtn.prop('disabled', false)
          }
        },
        displayText: function (item) {
          var unescapedText = document.createElement('textarea')
          unescapedText.innerHTML = item.name
          return item.code + ' : ' + unescapedText.value
        }
      })
    } catch (err) {
      console.log('no type ahead available..')
    }
    $input.keypress(function () {
      $tyelf.set('currentItem', null)
      $gobtn.prop('disabled', true)
    })
  },
  changeFindBy: function (newType) {
    var reportMeta = this.get('reportMeta')
    reportMeta.reportType = newType
    reportMeta.mainId = undefined
    if (reportMeta.reportType === 1) {
      reportMeta.reportName = 'Auction Report'
    } else if (reportMeta.reportType === 2) {
      reportMeta.reportName = 'User Report'
    } else {
      reportMeta.reportName = 'Session Report'
    }
  }
})
