PageApp.Controllers.LoginRouter = function (config) {
  function initEvents () {
    myApp.vent.on('authentication:success', function (userModel) {
      if (userModel) {
        myApp.ent.user.set(userModel)
      }
    }, this)

    myApp.vent.on('authentication:remove', function (isSilent) {
      myApp.refreshModels({ callback: function () {} })
    }, this)
  }

  return {
    initialize: function (models) {
      initEvents()
    }
  }
}
