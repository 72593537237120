PageApp.Controllers.MakeOfferRouter = function (config) {
  function initEvents () {
    myApp.vent.on('makeoffer:display', function (config) {
      if (!this.modalInProgress) {
        this.modalInProgress = true
        var view
        if (config.model.get('isMultiItem') && config.model.get('numberOfUnits') > 1) {
          view = new PageApp.Views.MultiOfferView({ model: config.model })
        } else {
          view = new PageApp.Views.MakeOfferView({ model: config.model })
        }
        myApp.myModalRegion.show(new PageApp.Views.ModalLayout({ view: view }))
      }
    }, this)
    myApp.vent.on('modal:closed', function (config) {
      this.modalInProgress = false
    }, this)
  }
  return {
    initialize: function (models) {
      initEvents()
    }
  }
}
