PageApp.Views.MyBidsAuctionFilterView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.summaryFilter,
  serializeData: function () {
    for (var key in this.model.attributes.auctionSummaries) {
      if (this.model.attributes.currentAuctionId === this.model.attributes.auctionSummaries[key].id) {
        this.model.attributes.auctionSummaries[key].active = true
      }
    }
    var content = {
      JspPublicCodes_JSP_CURRENTLY_VIEWING: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CURRENTLY_VIEWING'),
      JspPublicCodes_JSP_MY_SALES_REFRESH_VIEW: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SALES_REFRESH_VIEW'),
      JspPublicCodes_JSP_VIEW_AUCTION: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_VIEW_AUCTION'),
      JspPublicCodes_JSP_MY_BIDS_AUCTION: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_BIDS_AUCTION'),
      contextPath: myApp.ent.config.get('contextPath')
    }
    _.extend(content, this.model.attributes)
    return content
  },
  onRender: function () {
    var current = this.$el.find('#myBidsAuctionLink' + this.model.get('auctionId'))
    if (current) {
      current.parent().addClass('active')
    }
  }
})
