PageApp.Views.MyBidsTypeFilterView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.myBidsTypeFilter,
  serializeData: function () {
    var searchTypes = myApp.ent.status.get('myBidsSearchTypes').filter(type => type.primary === true)
    var searchTypeId = myApp.ent.user.getPreference('searchTypeId')
    for (var key in searchTypes) {
      var searchType = searchTypes[key]
      searchType.label = myApp.reqres.request('i16:getString', 'MyBidsSearchTypeEnum_' + searchType.name)
      searchType.active = (searchType.id === searchTypeId) ? 'active' : ''
    }
    var query = '?page='
    if (!this.model.attributes.pageIndex) {
      query += '0'
    } else {
      query += this.model.attributes.pageIndex
    }
    var content = {
      searchTypes: searchTypes,
      contextPath: myApp.ent.config.get('contextPath'),
      query: query
    }
    return content
  }
})
