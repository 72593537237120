PageApp.Ent.Timed2BaseHelper = Backbone.Model.extend({
  defaults: {
    oneday: 24 * 60 * 60 * 1000,
    userStatuses: {
      NOT_WINNING: 0,
      UNDER_RESERVE: 1,
      ITEM_OPEN_USER_HAS_WINNING_BID: 4,
      OUTBID_BY_AUTOBID: 5,
      OUTBID_BY_ANOTHER_BID: 6,
      BIDDER_NOT_APPROVED: 7,
      ITEM_WON: 8,
      ITEM_LOST: 9,
      ITEM_CLOSED: 10,
      ITEM_LOST_TO_MAKE_OFFER: 11,
      ITEM_WON_BY_MAKE_OFFER: 12,
      ITEM_LOST_TO_MAKE_OFFER_NO_BIDS: 13,
      ITEM_LOST_TO_BUY_NOW: 14,
      ITEM_WON_BY_BUY_NOW: 15,
      ITEM_LOST_TO_BUY_NOW_NO_BIDS: 16,
      ITEM_CLOSED_USER_HAS_ACCEPTED_OFFERS: 17,
      ITEM_CLOSED_USER_HAS_NO_ACCEPTED_OFFERS: 18,
      ITEM_OPEN_USER_HAS_ACCEPTED_OFFERS: 19,
      ITEM_OPEN_USER_HAS_NO_ACCEPTED_OFFERS: 20,
      ITEM_WON_BY_TRANSFER: 21,
      ITEM_LOST_TO_TRANSFER: 22,
      ITEM_WON_BY_TENDER_OFFER: 23,
      ITEM_LOST_TO_TENDER_OFFER: 24,
      REGISTERED_SUSPENDED: 30,
      REGISTERED_PAY_DEPOSIT: 31,
      REGISTERED_PENDING: 32,
      REGISTERED_APPROVED: 33,
      REGISTERED_AWAITING_DEPOSIT: 34,
      REGISTERED_NOT: 35,
      REGISTERED_INSUFFICIENT_DATA: 36,
      REGISTERED_ISSUE_WITH_DEPOSIT: 37

    },
    itemStatuses: {
      NEW: 1,
      DRAFT: 2,
      PREVIEW: 3,
      LIVE: 4,
      WITHDRAWN: 5,
      SOLD: 6,
      SOLD_MAKE_OFFER: 7,
      UNSOLD: 8,
      SUSPENDED: 9,
      SOLD_BUY_NOW: 10,
      SOLD_BULK_BUY_FULL: 11,
      SOLD_BULK_BUY_PARTIAL: 12,
      OPEN_BULK_BUY_PARTIAL: 13,
      WITHDRAWN_ENDED: 14,
      TRANSFERRED: 15
    }
  },
  getPlaceBidOfferModel: function (attributes) {
    var offerButtonLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_MAKE_OFFER')
    var offerAction = 'makeoffer'
    var offerActionId = 'makeoffer'
    var offerEnabled = (attributes.timedBiddingInfo.buyNowEnabled || attributes.timedBiddingInfo.makeOfferEnabled || attributes.timedBiddingInfo.transferEnabled || attributes.timedBiddingInfo.tenderEnabled || attributes.timedBiddingInfo.tenderPctEnabled)
    var offerMessage
    if (attributes.timedBiddingInfo.buyNowEnabled) {
      offerAction = 'buynow'
      offerActionId = 'buynow'
      offerButtonLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BUY_NOW') + ' ' + attributes.localeSensitive.currency.symbol + myApp.utils.types.toFormattedNumber(attributes.timedBiddingInfo.minimumOfferOrBuyNowPrice)
    } else if (attributes.timedBiddingInfo.transferEnabled) {
      offerAction = 'transfer'
      offerActionId = 'transfer'
      offerButtonLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_TRANSFER')
    } else if (attributes.timedBiddingInfo.tenderEnabled) {
      offerAction = 'tender'
      offerActionId = 'tender'
      if (!attributes.timedUserInfo.maxNumberOfOffersMade && !this.isClosed(attributes)) {
        offerButtonLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_TENDER')
      } else {
        offerButtonLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_VIEW_TENDER_OFFERS')
      }
    } else if (attributes.timedBiddingInfo.tenderPctEnabled) {
      offerAction = 'tenderpct'
      offerActionId = 'tenderpct'
      if (!attributes.timedUserInfo.maxNumberOfOffersMade && !this.isClosed(attributes)) {
        offerButtonLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_TENDER')
      } else {
        offerButtonLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_VIEW_TENDER_OFFERS')
      }
    }
    if (myApp.ent.user.isNotLoggedIn() || attributes.registrant.status !== 2) {
      offerAction += ' disabled'
    }
    // turn buy now off if bidding has exceeded the price..
    if (attributes.timedBiddingInfo.buyNowEnabled) {
      var highest = Math.max(attributes.timedBiddingInfo.highestBid, attributes.timedBiddingInfo.openingBid)
      if (highest >= attributes.timedBiddingInfo.minimumOfferOrBuyNowPrice) {
        offerEnabled = false
      }
    }
    if (attributes.timedBiddingInfo.tenderEnabled || attributes.timedBiddingInfo.tenderPctEnabled) {
      offerMessage = myApp.ent.purchaseEntity.getTenderOfferMessage(attributes)
    } else {
      offerMessage = myApp.ent.purchaseEntity.getOfferMessage(attributes)
    }
    // For tender offers we handle offerEnabled differently as we potentially want to display the 'View Tender Offers' button.
    if (attributes.timedBiddingInfo.tenderEnabled || attributes.timedBiddingInfo.tenderPctEnabled) {
      var noOffersMade = !attributes.timedUserInfo.offerModels || attributes.timedUserInfo.offerModels.length === 0
      if ((this.isClosed(attributes) && noOffersMade) ||
        attributes.timedBiddingInfo.statusId !== 4 ||
        !this.isPurchaseActive(attributes)) {
        offerEnabled = false
      }
    } else {
      if (this.isClosed(attributes) || attributes.timedBiddingInfo.statusId !== 4 ||
        !this.isPurchaseActive(attributes) || attributes.timedUserInfo.maxNumberOfOffersMade) {
        offerEnabled = false
      }
    }
    var model = {
      offerButtonLabel: offerButtonLabel,
      offerEnabled: offerEnabled,
      offerAction: offerAction,
      offerActionId: offerActionId,
      offerMessage: offerMessage
    }
    return model
  },
  isWinning: function (attributes) {
    if (myApp.ent.user.isNotLoggedIn()) return false
    if (this.isClosed(attributes)) {
      return false
    }
    if (myApp.ent.purchaseEntity.getHighestAcceptedOffer(attributes)) {
      return true // user is partially winning..
    }
    if (this.isHighestBidder(attributes)) {
      if (attributes.timedBiddingInfo.highestBid >= attributes.timedBiddingInfo.reserve) {
        return true
      }
    }
    // return (attributes.timedBiddingInfo.highestOfferUID === myApp.ent.user.get('id'));
    return false
  },
  isWon: function (attributes) {
    if (myApp.ent.user.isNotLoggedIn()) return false
    if (!this.isClosed(attributes)) return false
    if (this.isHighestBidder(attributes)) {
      if (attributes.timedBiddingInfo.highestBid > attributes.timedBiddingInfo.reserve || attributes.itemType.webcastItem) {
        return true
      }
    }
    if (myApp.ent.purchaseEntity.getHighestAcceptedOffer(attributes)) {
      return true // user is partially winning..
    }
    return false
  },
  isLosing: function (sellingStatus) {
    return (sellingStatus === this.attributes.userStatuses.OUTBID_BY_AUTOBID ||
      sellingStatus === this.attributes.userStatuses.OUTBID_BY_ANOTHER_BID ||
      sellingStatus === this.attributes.userStatuses.UNDER_RESERVE)
  },
  isLost: function (sellingStatus) {
    return (sellingStatus === this.attributes.userStatuses.ITEM_LOST ||
      sellingStatus === this.attributes.userStatuses.ITEM_LOST_TO_MAKE_OFFER ||
      sellingStatus === this.attributes.userStatuses.ITEM_LOST_TO_MAKE_OFFER_NO_BIDS ||
      sellingStatus === this.attributes.userStatuses.ITEM_LOST_TO_BUY_NOW ||
      sellingStatus === this.attributes.userStatuses.ITEM_LOST_TO_BUY_NOW_NO_BIDS ||
      sellingStatus === this.attributes.userStatuses.ITEM_CLOSED_USER_HAS_NO_ACCEPTED_OFFERS)
  },
  getActualOrNormalEndTime: function (attributes) {
    if (attributes.timedBiddingInfo.actualEndTime > 0) {
      return attributes.timedBiddingInfo.actualEndTime
    } else {
      return attributes.timedBiddingInfo.endTime
    }
  },
  isClosed: function (attributes) {
    if (attributes.timedBiddingInfo.statusId === this.attributes.itemStatuses.DRAFT) {
      return false
    }
    return (this.getActualOrNormalEndTime(attributes) < myApp.ent.config.getNormalisedTimeMillis()) || this.isWhollyPurchased(attributes)
  },
  isPurchaseActive: function (attributes) {
    if (attributes.itemType.listingItem) {
      return true
    } else if (attributes.timedBiddingInfo.purchaseCutoff) {
      var purchaseCutoffInMillis = attributes.timedBiddingInfo.purchaseCutoff * 86400000
      return attributes.timedBiddingInfo.endTime > myApp.ent.config.getNormalisedTimeMillis() + purchaseCutoffInMillis
    } else {
      return false
    }
  },
  isOpen: function (attributes) {
    var rightStatus = attributes.timedBiddingInfo.statusId === this.attributes.itemStatuses.LIVE || attributes.timedBiddingInfo.statusId === this.attributes.itemStatuses.OPEN_BULK_BUY_PARTIAL
    return rightStatus && attributes.timedBiddingInfo.publicVisible && !this.isClosed(attributes)
  },
  isWithdrawn: function (attributes) {
    return attributes.timedBiddingInfo.statusId === this.attributes.itemStatuses.WITHDRAWN
  },
  isWithdrawnEnded: function (attributes) {
    return attributes.timedBiddingInfo.statusId === this.attributes.itemStatuses.WITHDRAWN_ENDED
  },
  isWhollyPurchased: function (attributes) {
    if (attributes.itemType.bulkBuyItem) {
      return attributes.timedBiddingInfo.numberOfUnitsRemaining <= 0
    } else {
      return attributes.timedBiddingInfo.offerAccepted
    }
  },
  isSuspendedDraftOrPreview: function (attributes) {
    return attributes.timedBiddingInfo.statusId === this.attributes.itemStatuses.SUSPENDED || attributes.timedBiddingInfo.statusId === this.attributes.itemStatuses.DRAFT || attributes.timedBiddingInfo.statusId === this.attributes.itemStatuses.PREVIEW
  },
  isAvailableForBidding: function (attributes) {
    return !this.isWithdrawn(attributes) &&
      !this.isSuspendedDraftOrPreview(attributes) &&
      !this.isClosed(attributes)
  },

  getBiddingAndTimeLeft: function (attributes, showTimeLeft) {
    var messages = {}
    if (attributes.itemType.webcasty) {
      messages = this.getBiddingAndTimeLeftWebcasty(attributes)
    } else if (attributes.itemType.bulkBuyItem) {
      messages = this.getBiddingAndTimeLeftBulkBuy(attributes)
    } else if (attributes.itemType.lotItem) {
      messages = this.getBiddingAndTimeLeftLot(attributes)
    } else {
      messages = this.getBiddingAndTimeLeftListing(attributes)
    }
    if (!messages.biddingStatusMessage && showTimeLeft) {
      return messages.timeLeftMessage
    }
    if (!messages.timeLeftMessage) {
      return messages.biddingStatusMessage
    }
    return messages.biddingStatusMessage + (showTimeLeft ? (' : ' + messages.timeLeftMessage) : '')
  },

  getBiddingAndTimeLeftListing: function (attributes) {
    var messages = {}
    if (this.isOpen(attributes)) {
      var timeZone = attributes.timeZone
      var endTime = attributes.timedBiddingInfo.endTime
      var msg = myApp.reqres.request('i16:getString', 'ItemMessageStatus_LISTING_ENDS_MESSAGE')
      var locale = myApp.ent.user.getLocale()
      var dateAndTimeStr = this.getTimezonedDateString(endTime, locale, timeZone.code) + ' ' + this.getTimezonedTimeString(endTime, timeZone.code)
      messages.biddingStatusMessage = msg.replace('{0}', dateAndTimeStr)
    } else {
      messages.biddingStatusMessage = this.getClosedBiddingMessage(attributes)
    }
    return messages
  },

  getBiddingAndTimeLeftLot: function (attributes) {
    var messages = {}
    if (this.isOpen(attributes)) {
      var timeZone = attributes.timeZone
      if (attributes.timedBiddingInfo.overtimeBidding) {
        messages.biddingStatusMessage = ''
      } else {
        // BD-2562 - Don't display bidding status for John Pye
        if (attributes.timedBiddingInfo.showBidStatusEndDate) {
          var now = myApp.ent.config.getNormalisedTimeMillis()
          var timeLeft = attributes.timedBiddingInfo.endTime - now
          if (timeLeft < this.get('oneday')) {
            messages.biddingStatusMessage = 'timeLeft less than 1 day'
            attributes.timedBiddingInfo.oneDayTimer = true
          } else {
            var msg = myApp.reqres.request('i16:getString', 'ItemMessageStatus_LOT_ENDS_MESSAGE')
            var locale = myApp.ent.user.getLocale()
            msg = msg.replace('{0}', this.getTimezonedDateString(attributes.timedBiddingInfo.endTime, locale, timeZone.code))

            msg = msg.replace('{1}', this.getTimezonedTimeString(attributes.timedBiddingInfo.endTime, timeZone.code))

            messages.biddingStatusMessage = msg
          }
        } else {
          messages.biddingStatusMessage = ''
        }
      }
    } else {
      messages.biddingStatusMessage = this.getClosedBiddingMessage(attributes)
    }
    return messages
  },
  getBiddingAndTimeLeftBulkBuy: function (attributes) {
    var messages = {}
    if (this.isOpen(attributes)) {
      var timeZone = attributes.timeZone
      var endTime = attributes.timedBiddingInfo.endTime
      var locale = myApp.ent.user.getLocale()
      var msg = myApp.reqres.request('i16:getString', 'ItemMessageStatus_BULKBUYITEM_ENDS_MESSAGE')

      var dateAndTimeStr = this.getTimezonedDateString(endTime, locale, timeZone.code) + ' ' + this.getTimezonedTimeString(endTime, timeZone.code)
      messages.timeLeftMessage = msg.replace('{0}', dateAndTimeStr)
    } else {
      messages.biddingStatusMessage = this.getClosedBiddingMessage(attributes)
    }
    return messages
  },

  getBiddingAndTimeLeftWebcasty: function (attributes) {
    var messages = {}
    if (this.isOpen(attributes) && attributes.timedBiddingInfo.publicVisible && !this.isClosed(attributes)) {
      var endTime = attributes.timedBiddingInfo.endTime
      var now = myApp.ent.config.getNormalisedTimeMillis()
      var timeZone = attributes.timeZone
      if (endTime > now) {
        // messages.timeLeftMessage = DateHelper.getTimeLeft(getDates().getAuctionStarts(), getDates().getTimeZone(), true);
        messages.timeLeftMessage = endTime
      } else {
        messages.timeLeftMessage = null
      }

      var msg = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_LIVE_BIDDING_STARTS')
      var locale = myApp.ent.user.getLocale()
      var auctionStarts = attributes.timedBiddingInfo.auctionStarts
      var auctionStartsDateStr = this.getTimezonedDateString(auctionStarts, locale, timeZone.code)
      var auctionStartsTimeStr = this.getTimezonedTimeString(auctionStarts, timeZone.code)

      if (msg.indexOf('{0, jdate, S}') > -1) {
        msg = msg.replace('{0, jdate, S}', auctionStartsDateStr)
      } else if (msg.indexOf('{0, jdate, F}') > -1) {
        msg = msg.replace('{0, jdate, F}', auctionStartsDateStr)
      }
      if (msg.indexOf('{0, jtime, S}') > -1) {
        msg = msg.replace('{0, jtime, S}', auctionStartsTimeStr)
      } else if (msg.indexOf('{0, jtime, F}') > -1) {
        msg = msg.replace('{0, jtime, F}', auctionStartsTimeStr)
      }

      messages.biddingStatusMessage = msg
      messages.timeLeftMessage = null
    } else {
      messages.biddingStatusMessage = this.getClosedBiddingMessage(attributes)
    }
    return messages
  },

  getClosedBiddingMessage: function (attributes) {
    if (attributes.itemType.webcasty) {
      return this.getWebcastyClosedBiddingMessage(attributes)
    } else {
      return this.getOtherClosedBiddingMessage(attributes)
    }
  },
  getOtherClosedBiddingMessage: function (attributes) {
    var biddingStatusMessage
    if ((this.isWithdrawn(attributes) || this.isWithdrawnEnded(attributes)) && attributes.timedBiddingInfo.publicVisible) {
      biddingStatusMessage = myApp.reqres.request('i16:getString', 'ItemMessageStatus_ITEM_WITHDRAWN')
    } else if (this.isSuspendedDraftOrPreview(attributes) || !attributes.timedBiddingInfo.publicVisible) {
      biddingStatusMessage = myApp.reqres.request('i16:getString', 'ItemMessageStatus_ITEM_SUSPENDED')
    } else if (this.isClosed(attributes)) {
      if (this.isWhollyPurchased(attributes)) {
        if (attributes.timedBiddingInfo.makeOfferEnabled) {
          biddingStatusMessage = myApp.reqres.request('i16:getString', 'ItemMessageStatus_LISTING_CLOSED_MESSAGE_SOLD_MAKE_OFFER')
        } else if (attributes.timedBiddingInfo.buyNowEnabled) {
          biddingStatusMessage = myApp.reqres.request('i16:getString', 'ItemMessageStatus_LISTING_CLOSED_MESSAGE_SOLD_BUY_NOW')
        } else if (attributes.timedBiddingInfo.tenderEnabled) {
          biddingStatusMessage = myApp.reqres.request('i16:getString', 'ItemMessageStatus_LISTING_CLOSED_MESSAGE_SOLD_TENDER')
        } else if (attributes.timedBiddingInfo.tenderPctEnabled) {
          biddingStatusMessage = myApp.reqres.request('i16:getString', 'ItemMessageStatus_LISTING_CLOSED_MESSAGE_SOLD_TENDER_PCT')
        } else if (attributes.timedBiddingInfo.transferEnabled) {
          biddingStatusMessage = myApp.reqres.request('i16:getString', 'ItemMessageStatus_LISTING_CLOSED_MESSAGE_TRANSFERRED')
        } else {
          biddingStatusMessage = 'Closed' // We should not see this message
        }
      } else {
        if (attributes.itemType.lotItem) {
          biddingStatusMessage = myApp.reqres.request('i16:getString', 'ItemMessageStatus_LOT_CLOSED_MESSAGE')
        } else {
          var timeZone = attributes.timeZone
          biddingStatusMessage = myApp.reqres.request('i16:getString', 'ItemMessageStatus_LISTING_CLOSED_MESSAGE')
          var locale = myApp.ent.user.getLocale()

          biddingStatusMessage = biddingStatusMessage.replace('{0}', this.getTimezonedDateString(this.getActualOrNormalEndTime(attributes), locale, timeZone.code))
          biddingStatusMessage = biddingStatusMessage.replace('{1}', this.getTimezonedTimeString(this.getActualOrNormalEndTime(attributes), timeZone.code))
        }
      }
    }
    return biddingStatusMessage
  },
  getWebcastyClosedBiddingMessage: function (attributes) {
    var biddingStatusMessage
    if ((this.isWithdrawn(attributes) || this.isWithdrawnEnded(attributes)) && attributes.timedBiddingInfo.publicVisible) {
      biddingStatusMessage = myApp.reqres.request('i16:getString', 'ItemMessageStatus_ITEM_WITHDRAWN')
    } else if (this.isSuspendedDraftOrPreview(attributes)) {
      biddingStatusMessage = myApp.reqres.request('i16:getString', 'ItemMessageStatus_ITEM_SUSPENDED')
    } else if (this.isClosed(attributes)) {
      biddingStatusMessage = myApp.reqres.request('i16:getString', 'ItemMessageStatus_LOT_CLOSED_MESSAGE')
    }
    return biddingStatusMessage
  },

  getTimezonedTimeString: function (dateTimeMs, timeZoneCode, format = 'hh:mmA z') {
    try {
      var endTimeTz = moment.tz(dateTimeMs, 'UTC').tz(timeZoneCode)
      return endTimeTz.format(format)
    } catch (err) {
      console.log(err)
      var formatter = new Intl.DateTimeFormat({ hour12: true, hour: '2-digit', minute: '2-digit' })
      var dtf = formatter.format(dateTimeMs)
      return dtf
    }
  },
  getTimezonedDateString: function (dateTimeMs, locale = 'en', timeZoneCode = 'UTC', format = 'MMM D') {
    moment.locale(locale)
    const endTimeTz = moment.tz(dateTimeMs, timeZoneCode)
    return endTimeTz.format(format)
  }

})
