PageApp.Views.ContactSellerView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.contactSeller,
  templateSuccess: hbs.templates.contactSellerSuccess,
  events: {
    'click .x-submit': 'handleSubmit',
    'click .x-cancel': 'handleCancel',
    'keyup #contactSellerMessage': 'handleKeyup'
  },
  serializeData: function () {
    var content = {
      title: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CONTACT_SELLER_OF_ITEM'),
      JspPublicCodes_JSP_CONTACT_SELLER_YOUR_MESSAGE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CONTACT_SELLER_YOUR_MESSAGE'),
      JSTextCodes_SUBMIT: myApp.reqres.request('i16:getString', 'JSTextCodes_SUBMIT'),
      JSTextCodes_CLOSE: myApp.reqres.request('i16:getString', 'JSTextCodes_CLOSE'),
      JspPublicCodes_JSP_CONTACT_SELLER_ABOUT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CONTACT_SELLER_ABOUT')

    }
    content = _.extend(content, this.model.attributes)
    return content
  },
  onRender: function () {
    if (this.model.get('message') && this.model.get('message').length > 0) {
      this.$el.find('.x-submit').removeClass('disabled')
    } else {
      this.$el.find('.x-submit').addClass('disabled')
    }
  },
  handleCancel: function (e) {
    var message = this.$el.find('#contactSellerMessage').val()
    this.model.set('message', message)
  },
  handleKeyup: function (e) {
    e.preventDefault()
    if (e.keyCode !== 13) {
      var input = $(e.currentTarget).val()
      if (input && input.length > 0) {
        this.$el.find('.x-submit').removeClass('disabled')
      } else {
        this.$el.find('.x-submit').addClass('disabled')
      }
    }
    return false
  },
  handleSubmit: function (e) {
    e.preventDefault()
    var message = this.$el.find('#contactSellerMessage').val()
    this.model.set('message', message)
    if (!message || message.length === 0) {
      myApp.vent.trigger('ui:notification', { text: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CONTACT_SELLER_PLEASE_ENTER_MESSAGE'), level: 'warning' })
      return
    }
    var bean = {
      '@class': 'com.bidlogix.scaffold.models.bidding.ContactSellerModel',
      message: this.model.get('message'),
      subject: this.model.get('title'),
      lotId: this.model.get('id'),
      userId: myApp.ent.user.get('id')
    }
    myApp.utils.ajax.postBean(bean, myApp.utils.ajax.getApiEndpoint('timed/contactSeller'), _.bind(function (timedResponse) {
      if (timedResponse.models.ErrorModel) {
        myApp.ent.user.checkLogout(timedResponse)
        myApp.vent.trigger('ui:notification', { text: timedResponse.models.ErrorModel.message, level: 'warning' })
      } else {
        var content = {
          message: this.model.get('message'),
          title: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CONTACT_SELLER_MESSAGE_TO_SELLER'),
          JspPublicCodes_JSP_CONTACT_SELLER_MESSAGE_SENT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CONTACT_SELLER_MESSAGE_SENT'),
          JSTextCodes_CLOSE: myApp.reqres.request('i16:getString', 'JSTextCodes_CLOSE')
        }
        this.$el.find('.modal-content').html(this.templateSuccess(content))
      }
    }, this))
    return false
  }
})
