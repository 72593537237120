PageApp.Views.MyBidsLayout = Backbone.Marionette.LayoutView.extend({

  getTemplate: function () {
    return hbs.templates.myBidsLayout
  },

  events: {
    'click .x-search-filter': 'handleClickFilter',
    'click .x-alert-filter': 'handleClickAlertFilter',
    'click .x-search-auction': 'handleClickAuction',
    'click .x-search-dates': 'handleClickDates',
    'click .x-remove-auction': 'handleUnclickAuction',
    'click .x-goto-mybids': 'handleGoto',
    'click .x-previous-mybids': 'handlePrevious',
    'click .x-next-mybids': 'handleNext'

  },
  regions: {
    currentFilterRegion: '#currentFilterRegion',
    typesFilterRegion: '#typesFilterRegion',
    summaryRegion: '#summaryRegion',
    layoutControlRegion: '#layoutControlRegion',
    datesFilterRegion: '#datesFilterRegion',
    biddingRegion: '#biddingRegion',
    pagingTopRegion: '#pagingTopRegion',
    pagingBottomRegion: '#pagingBottomRegion',
    currentAlertsFilterRegion: '#currentAlertsFilterRegion',
    alertsFilterRegion: '#alertsFilterRegion'
  },
  serializeData: function () {
    myApp.ent.user.setPreference('filtersActive', false)
    var myLabel
    if (this.model.attributes.totalNumberOfMatches === 1) {
      myLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM')
    } else {
      myLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEMS')
    }

    var content = {
      showItemAlerts: myApp.ent.webApp.get('showItemAlerts'),
      totalNumberOfMatches: this.model.attributes.totalNumberOfMatches,
      numbItemsLabel: myLabel,
      JspPublicCodes_JSP_FOUND: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_FOUND'),
      JspPublicCodes_JSP_HEADER_MY_BIDS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_HEADER_MY_BIDS'),
      JspPublicCodes_JSP_HEADER_MY_ALERTS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_HEADER_MY_ALERTS')
    }

    return content
  },
  onRender: function () {
    var searchType = myApp.ent.status.getMyBidsSearchType(this.model.get('parameters').parameters.searchType)
    myApp.ent.user.setPreference('searchTypeId', searchType.id)
    this.layoutControlRegion.show(new PageApp.Views.ControlLayout({
      model: new PageApp.Ent.LayoutControl({ parameters: this.model.get('parameters') }),
      paging: this.model,
      isShowOpenDisabled: true
    }))
    var currentFilterModel = new PageApp.Ent.BasicEntity()
    var totalNumberOfMatches = (searchType.id === 6) ? 0 : this.model.get('pagingModel').totalNumberOfMatches
    currentFilterModel.set('currentFilterModel', { 'totalNumberOfMatches': totalNumberOfMatches })
    this.currentFilterRegion.show(new PageApp.Views.CurrentFilterView({ model: currentFilterModel }))

    var paging = this.model.get('pagingModel')

    var filters = new PageApp.Ent.BasicEntity()
    filters.set('currentAuctionId', this.model.get('parameters').parameters.auctionId)
    filters.set('page', paging.page)
    this.getRegion('typesFilterRegion').show(new PageApp.Views.MyBidsTypeFilterView({ model: filters }))

    if (this.model.get('showDateFilters')) {
      this.getRegion('datesFilterRegion').show(new PageApp.Views.MyBidsDateFilterView({ model: new PageApp.Ent.BasicEntity(this.model.get('parameters').parameters) }))
    }

    if (this.model.get('auctionSummaries')) {
      var summaries = new PageApp.Ent.BasicEntity()
      summaries.set('auctionSummaries', this.model.get('auctionSummaries'))
      summaries.set('currentAuctionId', this.model.get('parameters').parameters.auctionId)
      summaries.set('searchTypeId', searchType.id)
      this.getRegion('summaryRegion').show(new PageApp.Views.MyBidsAuctionFilterView({ model: summaries }))
    }

    if (this.model.get('showAlertsFilters')) {
      var currentAlertsFilterModel = new PageApp.Ent.BasicEntity()
      var totalNumberOfAlertMatches = (searchType.id === 6) ? this.model.get('pagingModel').totalNumberOfMatches : 0
      currentAlertsFilterModel.set('currentFilterModel', { 'totalNumberOfMatches': totalNumberOfAlertMatches })
      this.currentAlertsFilterRegion.show(new PageApp.Views.CurrentFilterView({ model: currentAlertsFilterModel }))

      var alertsFilter = new PageApp.Ent.BasicEntity(this.model.get('alertsFilter'))
      alertsFilter.set('currentAlertId', this.model.get('parameters').parameters.alertId)
      alertsFilter.set('page', paging.page)
      this.getRegion('alertsFilterRegion').show(new PageApp.Views.MyBidsAlertsFilterView({ model: alertsFilter }))
    }

    if (searchType.pageable && paging.numPages > 1) {
      paging.controller = '-mybids'
      paging.owner = 'mybids'
      paging.paginationPosition = 'top'
      this.getRegion('pagingTopRegion').show(new PageApp.Views.PagingView({ model: this.model }))
      paging.paginationPosition = 'bottom'
      this.getRegion('pagingBottomRegion').show(new PageApp.Views.PagingView({ model: this.model }))
    }
    myApp.vent.trigger('timed:display', { region: this.getRegion('biddingRegion'), model: this.model.get('timedModels') })
  },
  handleGoto: function (e) {
    if (e) e.preventDefault()
    this.model.get('parameters').parameters.page = $(e.currentTarget).data('goto')
    this.fetch('/myBids' + this.getCommand())
  },
  handlePrevious: function (e) {
    if (e) e.preventDefault()
    this.model.get('parameters').parameters.page--
    this.fetch('/myBids' + this.getCommand())
  },
  handleNext: function (e) {
    if (e) e.preventDefault()
    this.model.get('parameters').parameters.page++
    this.fetch('/myBids' + this.getCommand())
  },
  handleClickFilter: function (e) {
    if (e) e.preventDefault()
    var searchTypeId = $(e.currentTarget).data('filter')
    myApp.ent.user.setPreference('searchTypeId', myApp.ent.status.getMyBidsSearchType(searchTypeId).id) // just makes sure this exists..
    myApp.ent.user.setPreference('filterId')
    this.model.get('parameters').parameters.searchType = searchTypeId
    this.model.get('parameters').parameters.page = 0
    this.model.get('parameters').parameters.auctionId = undefined
    this.model.get('parameters').parameters.alertId = undefined
    // Show the spinner. This will disappear when the filter region is re-rendered.
    // this.currentFilterRegion.$el.find('.spinner').css('visibility', 'visible');
    this.fetch('/myBids' + this.getCommand())
  },
  handleClickAlertFilter: function (e) {
    if (e) e.preventDefault()
    var alertId = $(e.currentTarget).data('alert')
    this.model.get('parameters').parameters.searchType = 6
    this.model.get('parameters').parameters.alertId = alertId
    this.model.get('parameters').parameters.page = 0
    this.model.get('parameters').parameters.auctionId = undefined
    this.fetch('/myBids' + this.getCommand())
  },
  handleClickDates: function (e) {
    if (e) e.preventDefault()
    var startDate = $(e.currentTarget).parent().find('#startDate').val()
    var endDate = $(e.currentTarget).parent().find('#endDate').val()
    this.model.get('parameters').parameters.startDate = startDate
    this.model.get('parameters').parameters.endDate = endDate
    this.model.get('parameters').parameters.page = 0
    this.fetch('/myBids' + this.getCommand())
  },
  handleClickAuction: function (e) {
    if (e) e.preventDefault()
    var auctionId = $(e.currentTarget).data('auction')
    this.model.get('parameters').parameters.auctionId = auctionId
    this.model.get('parameters').parameters.page = 0
    this.fetch('/myBids' + this.getCommand())
  },
  handleUnclickAuction: function (e) {
    if (e) e.preventDefault()
    this.model.get('parameters').parameters.auctionId = null
    this.model.get('parameters').parameters.page = 0
    this.fetch('/myBids' + this.getCommand())
  },
  fetch: function (url) {
    url = myApp.ent.config.get('contextPath') + url
    myApp.controllers.application.doRoute({ 'href': url, 'manual': true })
  },
  getCommand: function () {
    var command = ''
    var cmd = this.model.get('parameters').parameters
    var searchTypeId = cmd.searchType
    if (!searchTypeId) {
      searchTypeId = 0
    }
    command += '/' + searchTypeId
    command += '?page=' + cmd.page
    if (cmd.auctionId) {
      command += '&auctionId=' + cmd.auctionId
    }
    if (cmd.alertId) {
      command += '&alertId=' + cmd.alertId
    }
    if (this.model.get('showDateFilters') && cmd.startDate && cmd.startDate.length > 0) {
      command += '&startDate=' + cmd.startDate
    }
    if (this.model.get('showDateFilters') && cmd.endDate && cmd.endDate.length > 0) {
      command += '&endDate=' + cmd.endDate
    }
    return command
  }
})
