PageApp.Views.CategoryFilterView = Backbone.Marionette.ItemView.extend({
  getTemplate: function () {
    return hbs.templates.categoryFilter
  },
  initialize: function (options) {
    this.options = options
  },
  events: {
    'click .x-category': 'handleCategory',
    'click #x-overflow': 'handleOverflow'
  },
  serializeData: function () {
    var myModel = this.model.attributes.categoryFilterModel
    var itemsToDisplay = myModel.filterItems
    var level = 'category'
    if (_.size(itemsToDisplay) > 0) {
      if (itemsToDisplay[0].item.parentId && itemsToDisplay[0].item.parentId > 0) {
        level = 'subCategory'
      }
    }
    itemsToDisplay.sort(this.categoryCompare)
    var content = {
      filterHeaderName: myModel.filterTitle,
      filterItems: itemsToDisplay,
      level: level,
      multiFilter: myModel.multiFilter
    }
    return content
  },
  categoryCompare: function (a, b) {
    return (a.item.name > b.item.name) ? 1 : ((b.item.name > a.item.name) ? -1 : 0)
  },
  handleCategory: function (e) {
    if (e) e.preventDefault()
    var selectedId = $(e.currentTarget).data('itemid')
    var level = $(e.currentTarget).data('level')
    var link = this.model.getLink(level, selectedId)
    myApp.utils.ajax.relocate(link)
  }

})
