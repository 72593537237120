PageApp.Controllers.MyBidsRouter = function (config) {
  var ent = {}

  function initEvents () {
    myApp.vent.on('mybids:display', function (model) {
      myApp.ent.user.setPreference('showFilters', false)
      if (model && model.myBidsModel) {
        ent.myBids = new PageApp.Ent.SearchFilters(model.myBidsModel)
      } else if (model) {
        ent.myBids = new PageApp.Ent.SearchFilters(model)
      } else {
        try {
          ent.myBids.set('timedModels', undefined)
        } catch (err) {}
      }
      // register event handler on all the anchors in this region now it is showing..
      myApp.mainRegion.on('show', function (view) {
        myApp.vent.trigger('application:rendered', { region: myApp.mainRegion })
      })
      myApp.mainRegion.show(new PageApp.Views.MyBidsLayout({ model: ent.myBids }))
    }, this)
    myApp.vent.on('layoutcontrol:display', function () {
      var page = myApp.ent.config.get('page')
      if (page === 'myBidsLand') {
        myApp.vent.trigger('mybids:display')
      }
    }, this)
  }
  return {
    initialize: function (models) {
      initEvents()
    }
  }
}
