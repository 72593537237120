PageApp.Views.ItemTypeFilterView = Backbone.Marionette.ItemView.extend({
  getTemplate: function () {
    return hbs.templates.itemTypeFilter
  },
  initialize: function (options) {
    this.options = options
  },
  events: {
    'click .x-type': 'handleType'
  },
  serializeData: function () {
    var myModel = this.model.attributes.itemTypeFilterModel
    var fCount = 0
    for (var key in myModel.filterItems) {
      if (myModel.filterItems[key].count > 0) fCount++
    }
    var content = {
      filterHeaderName: myModel.filterTitle,
      filterItems: myModel.filterItems,
      queryParameterName: 'itemType',
      displayFilter: (fCount > 1)
    }
    return content
  },
  handleType: function (e) {
    if (e) e.preventDefault()
    var itemId = $(e.currentTarget).data('itemid')
    var link = this.model.getLink('itemType', itemId)
    myApp.utils.ajax.relocate(link)
  }
})
