PageApp.Controllers.ContentRouter = function () {
  function initEvents () {
    myApp.vent.on('content:display', function (model) {
      myApp.mainRegion.show(new PageApp.Views.ContentView({ model: new PageApp.Ent.BasicEntity(model) }))
    }, this)
  }
  return {
    initialize: function () {
      initEvents()
    }
  }
}
