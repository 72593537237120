PageApp.Ent.Address = Backbone.Model.extend({
  initialise: function (options) {
  },
  defaults: {
    '@class': 'com.bidlogix.scaffold.models.address.AddressModel',
    'errors': {}
  },
  url: function () {
    // if using backbone sync: return myApp.ent.config.get('contextPath') + '/address/' + myApp.ent.user.get('id');
    return '/address/' + myApp.ent.user.get('id')
  },
  validate: function (attrs, options) {
    var errors = {}
    errors.addressLine1 = myApp.utils.validation.stringIsEmpty(attrs.addressLine1)
    errors.county = myApp.utils.validation.stringIsEmpty(attrs.county)
    errors.postcode = myApp.utils.validation.stringIsEmpty(attrs.postcode)

    this.set('errors', errors)
    var valid = true
    for (var key in errors) {
      if (errors[key]) valid = false
    }
    return valid
  }
})
