PageApp.Views.FiltersLayout = Backbone.Marionette.LayoutView.extend({
  getTemplate: function () {
    return hbs.templates.filtersLayout
  },
  initialize: function (options) {
    this.options = options
  },
  regions: {
    saleInfoRegion: '#saleInfoRegion',
    currentFilterRegion: '#currentFilterRegion',
    categoriesRegion: '#categoriesRegion',
    auctionTypeRegion: '#auctionTypeRegion',
    itemTypeRegion: '#itemTypeRegion',
    continentsRegion: '#continentsRegion',
    infoRegion: '#infoRegion'
  },
  serializeData: function () {
    var saleInfoActive = myApp.ent.user.getPreference('saleInfoActive')
    var filtersActive = myApp.ent.user.getPreference('filtersActive')
    var showSaleInfoInPage = myApp.ent.user.getPreference('showSaleInfoInPage')
    var showOpenOnlyChecked = (this.model.get('parameters').parameters.showOpenOnly) ? ' checked ' : ''
    var isAuctionLand = (myApp.ent.config.get('page') === 'auctionland')
    var isMarketLand = (myApp.ent.config.get('page') === 'marketland')

    if (showSaleInfoInPage) {
      if (this.model.get('currentFilterModel').filtering || showOpenOnlyChecked) {
        filtersActive = 'active'
      }
    }

    if (showSaleInfoInPage && isMarketLand) {
      filtersActive = 'active'
    }

    var content = {
      showSaleInfoInPage: showSaleInfoInPage,
      filtersActive: filtersActive,
      saleInfoActive: saleInfoActive,
      isAuctionLand: isAuctionLand
    }
    return content
  },
  onRender: function () {
    var showOpenOnly = this.model.get('parameters').parameters.showOpenOnly
    var showSaleInfoInPage = myApp.ent.user.getPreference('showSaleInfoInPage')
    var isAuctionLand = (myApp.ent.config.get('page') === 'auctionland')

    if (showSaleInfoInPage && isAuctionLand) {
      this.saleInfoRegion.show(new PageApp.Views.SaleInfoView({ model: myApp.ent.saleInfo, showSaleInfoInPage: true }))
    }

    this.currentFilterRegion.show(new PageApp.Views.CurrentFilterView({ model: this.model }))
    if (showOpenOnly) {
      return
    }

    var showContinents = true
    if (myApp.ent.config.get('page') === 'auctionland') {
      showContinents = false
    }
    if (this.model.get('mpInfoModel')) {
      this.infoRegion.show(new PageApp.Views.MarketplaceInfoView({ model: this.model }))
    }
    if (this.model.get('currentFilterModel').totalNumberOfMatches > 0) {
      if (this.model.get('categoryFilterModel').multiFilter) {
        this.categoriesRegion.show(new PageApp.Views.CategoryTreesFilterView({ model: this.model }))
      } else {
        this.categoriesRegion.show(new PageApp.Views.CategoryFilterView({ model: this.model }))
      }
    }
    if (showContinents && !this.model.get('currentFilterModel').countryFiltering) this.continentsRegion.show(new PageApp.Views.ContinentFilterView({ model: this.model }))
    if (!this.model.get('currentFilterModel').itemTypeFiltering) this.itemTypeRegion.show(new PageApp.Views.ItemTypeFilterView({ model: this.model }))
    if (!this.model.get('currentFilterModel').auctionTypeFiltering) this.auctionTypeRegion.show(new PageApp.Views.AuctionTypeFilterView({ model: this.model }))
  }
})
