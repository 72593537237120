PageApp.Ent.User = Backbone.Model.extend({
  url: function () {
    return myApp.ent.header.get('contextPath') + '/user'
  },
  defaults: {
    accountNonExpired: false,
    accountNonLocked: false,
    activated: null,
    adminLoggedIn: false,
    adminLoginPermitted: false,
    age: 0,
    authorities: [],
    billingAddress: null,
    credentialsNonExpired: false,
    currencySymbolAsPrefix: true,
    displayName: 'anon@bidlogix.net',
    email: null,
    enabled: false,
    externalUserRef: null,
    forcePasswordReset: false,
    forename: null,
    hasBillingAddress: false,
    hasMainAddress: false,
    hasShippingAddress: false,
    id: null,
    inserted: 0,
    language: { code: 'en', id: 1, name: null, selected: false },
    lastLoggedIn: null,
    localeFormat: 'en_GB',
    localeInternal: '1_1',
    loggedIn: false,
    loginAdminError: false,
    mainAddress: null,
    outbidEmailsOk: false,
    password: null,
    penultimateLogin: null,
    phoneVerified: false,
    publicVendorAdmin: false,
    receiveEmailsOk: false,
    redFlag: false,
    redFlagReason: null,
    rolesAndPrivileges: null,
    shippingAddress: null,
    signupSource: null,
    statusId: null,
    surname: null,
    systemAdmin: false,
    thousandsSeparator: ',',
    timeZone: { code: 'Europe/London', id: 3, name: '(GMT) UTC', selected: false, zone: null },
    title: null,
    updated: null,
    username: 'anon@bidlogix.net',
    verifiedPhoneNumber: null,
    webAppId: null,
    whitelist: false
  },
  // suppresses the on change event!
  validate: function (attrs) {
    if (attrs.age < 0) {
      return 'age must be 0 or greater'
    }
    if (attrs.username === '') {
      return 'username must be set..'
    }
  },
  setPreference: function (name, value) {
    if (!this.get('preferences')) {
      this.set('preferences', {})
    }
    this.get('preferences')[name] = value
  },
  getPreference: function (name, defaults) {
    if (!this.get('preferences')) {
      this.set('preferences', {})
    }
    var value = this.get('preferences')[name]
    if (typeof value === 'undefined' && defaults && defaults.value) {
      value = defaults.value
      this.get('preferences').name = defaults.value
    }
    if (typeof value === 'undefined' && name === 'layoutForBidding') {
      var defLayout = 'list'
      if (myApp.ent.webApp.get('useGalleryLayoutForBidding')) {
        defLayout = 'grid'
      }
      this.setPreference(name, defLayout)
      value = defLayout
    }
    if (name === 'layoutForBidding') {
      if (myApp.ent.config.get('page') === 'itemland') {
        this.setPreference('layoutForBidding', 'grid')
        value = 'grid'
      }
    }
    return value
  },
  getLocale: function () {
    var locale = this.get('localeFormat')
    if (!locale) return 'en-GB'
    return locale.replace('_', '-')
  },
  isLoggedIn: function () {
    return this.get('id') && this.get('id') > 0
  },
  checkLogout: function (timedResponse) {
    try {
      if (!timedResponse.models.ErrorModel.loggedIn && myApp.ent.status.isRabbitSecure(timedResponse.actionCode)) {
        myApp.ent.user = new PageApp.Ent.User()
        myApp.vent.trigger('user:logged:out')
      }
    } catch (err) {
      console.log('error checking the error!', err)
    }
  },
  isNotLoggedIn: function () {
    return !this.isLoggedIn()
  }
})

PageApp.Ent.UserRegistration = Backbone.Model.extend({
  getRegistrantId: function () {
    var registrationId = this.get('registrationId')
    if (!registrationId) {
      return -1
    }
    return registrationId
  }

})
