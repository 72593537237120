PageApp.Views.ItemImageView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.itemImage,
  events: {
  },
  serializeData: function () {
    // see PageApp.Views.TimedItemLayout.renderItemImage() for the model. The model here is a subset of the full
    // [BiddingItemModel] model for rendering and enabling bidding (overkill for this little view).
    var content = {
      lbDims: {}
    }
    _.extend(content, this.model.attributes)
    return content
  },
  onRender: function () {
    this.prettyphoto()
  },
  prettyphoto: function () {
    /* Pretty Photo */
    // WARNING Do NOT change youtube_link since it is used
    // in the aa_listing.description field
    // to enable videos of Lots to loaded in PrettyPhoto
    var prettyPhotoOptions = {
      show_title: false,
      social_tools: false,
      allow_resize: true,
      deeplinking: false,
      overlay_gallery: false
    }
    try {
      this.$el.find('.youtube_link').prettyPhoto(prettyPhotoOptions)
      this.$el.find("a[rel^='prettyPhoto']").prettyPhoto(prettyPhotoOptions)
    } catch (err) {}
  }
})
