PageApp.Views.MessageView = Backbone.Marionette.ItemView.extend({
  getTemplate: function () {
    return hbs.templates.message
  },
  serializeData: function () {
    var content = {
      contextPath: myApp.ent.config.get('contextPath')
    }
    content = _.extend(content, this.model.attributes)
    return content
  }
})
