PageApp.Controllers.I16StringsRouter = function () {
  var self = this
  function initEvents () {
    myApp.vent.on('i16:display', function (config) {
    }, this)
  }
  function initCommands () {
    myApp.reqres.setHandler('i16:getString', function (codeAndSubs) {
      // For backwards compatibility, if codeAndSubs.code is undefined then we have been passed the code as a string
      // Otherwise it should be {code: 'theCode', substitutions: ['sub1','sub2','sub3']}
      var code = codeAndSubs.code ? codeAndSubs.code : codeAndSubs
      var substitutions = codeAndSubs.substitutions
      if (!code) {
        return ''
      } else {
        var i16String = self.i16Strings[code]
        if (!i16String) {
          return code
        } else if (!substitutions) {
          return i16String
        } else {
          for (var i = 1; i <= substitutions.length; i++) {
            i16String = i16String.replace(new RegExp('\\{' + i + '}', 'g'), substitutions[i - 1])
          }
          return i16String
        }
      }
    })
  }
  return {
    initialize: function (strings) {
      self.i16Strings = strings || {}
      initEvents()
      initCommands()
    }
  }
}
