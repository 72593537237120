PageApp.ValidationUtils = function () {
  return {
    stringEndsWith: function (str, suffix) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1
    },

    handleScrollTo: function (target) {
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.parent().offset().top
        }, 1000)
      }
    },

    stringIsNotEmpty: function (stringToCheck) {
      return !this.stringIsEmpty(stringToCheck)
    },

    stringIsEmpty: function (stringToCheck) {
      if (!this.existsAndPositiveLength(stringToCheck)) {
        // "no string"? empty
        return true
      }
      var matchedString = stringToCheck.match(/^\s*$/m)
      if (!this.existsAndPositiveLength(matchedString)) {
        // no whitespace matched? not empty
        return false
      }
      // .match() returns an /array/ of matched sub-strings of the original string
      // so if the first offset in the matchedString array equals the whole string passed - the whole string passed was whitespace
      // whitespace matched whole string? empty
      return (matchedString[0] === stringToCheck)
    },

    existsAndPositiveLength: function (obj) {
      // note that (typeof obj !== 'undefined') is the only thing that appears to stop IE complaining that obj is undefined!
      return this.objectExists(obj) && obj.length !== 0
    },

    objectExists: function (obj) {
      return typeof obj !== 'undefined' && obj !== null
    },

    showFieldError: function ($field, errorCode) {
      var inputName = $field.attr('name')
      var $inputContainerDiv = $('div.ic-' + inputName)
      var $errorMessageSpan = $('span.errors-' + inputName)
      var $additionalHighlightArea = $('.error-aha-' + inputName)

      var errorMessage = this.existsAndPositiveLength(errorCode) ? myApp.reqres.request('i16:getString', errorCode) : ''

      if (this.objectExists($inputContainerDiv)) {
        $inputContainerDiv.addClass('has-error')
      }
      if (this.objectExists($errorMessageSpan)) {
        $errorMessageSpan.html(errorMessage).addClass('help-block')
      }
      if (this.objectExists($additionalHighlightArea)) {
        $additionalHighlightArea.addClass('alert-danger').addClass('alert')
      }
    },

    showGlobalError: function (errorCode) {
      var $globalErrorDiv = $('div.errors-global')
      var errorMessage = this.existsAndPositiveLength(errorCode) ? myApp.reqres.request('i16:getString', errorCode) : ''

      if (this.objectExists($globalErrorDiv)) {
        $globalErrorDiv.addClass('alert-danger').addClass('alert').append('<p>' + errorMessage + '</p>')
        this.handleScrollTo($globalErrorDiv)
      }
    },

    clearGlobalErrors: function () {
      var $globalErrorDiv = $('div.errors-global')

      if (this.objectExists($globalErrorDiv)) {
        $globalErrorDiv.removeClass('alert-danger').removeClass('alert').html('')
      }
    },

    clearFieldError: function ($field) {
      var inputName = $field.attr('name')
      var $inputContainerDiv = $('div.ic-' + inputName)
      var $errorMessageSpan = $('span.errors-' + inputName)
      var $additionalHighlightArea = $('.error-aha-' + inputName)

      if (this.objectExists($inputContainerDiv)) {
        $inputContainerDiv.removeClass('has-error')
      }
      if (this.objectExists($errorMessageSpan)) {
        $errorMessageSpan.html('').removeClass('help-block')
      }
      if (this.objectExists($additionalHighlightArea)) {
        $additionalHighlightArea.removeClass('alert-danger').removeClass('alert')
      }
    },

    clearAllErrors: function () {
      this.clearGlobalErrors()
      var $elf = this
      $(':input:not(:button)').each(function () {
        var $input = $(this)
        try {
          $elf.clearFieldError($input)
        } catch (e) {
          if (console && console.log) console.log('error ' + e)
        }
      })
    },

    renderErrors: function (errors) {
      var $elf = this
      this.clearAllErrors()
      var i = 0
      errors.each(function (error) {
        var errorCode = error.get('code')
        i++
        if ($elf.existsAndPositiveLength(error.get('field'))) {
          var $field = $('[name=' + error.get('field') + ']')
          $elf.showFieldError($field, errorCode)
          if (i <= 1) {
            myApp.utils.validation.handleScrollTo($field)
          }
        } else {
          $elf.showGlobalError(errorCode)
        }
      })
    },

    isValidEmail: function (email) {
      return this.existsAndPositiveLength(email) && email.match(/^([^.@]+)(\.[^.@]+)*@([^.@]+\.)+([^.@]+)$/i)
    },

    isValidUsername: function (username) {
      return this.existsAndPositiveLength(username) &&
        username.length >= 6 && username.length <= 20 &&
        username.match(/^[0-9A-Za-z\-_.]{6,20}$/)
    },

    isValidPhoneNumber: function (phoneNumber) {
      return phoneNumber.match(/[0-9]/) && phoneNumber.match(/^[0-9\s()+-]+$/)
    },

    isValidInternationalPhoneNumber: function (phoneNumber) {
      return phoneNumber.match(/[0-9]/) && phoneNumber.match(/^\+(?:[0-9] ?){6,14}[0-9]$/)
    },

    elementExists: function (selector) {
      return $(selector).length > 0
    },

    registerClearListener: function (id) {
      // Registers a listener on a field so it's error message disappears when clicked.
      $('#' + id).focus(function () {
        this.resetFieldError(id)
      })
      $('.' + id).focus(function () {
        $('#' + id + 'Errors').text('')
      })
    },

    changeFieldError: function (id, text) {
      $('#' + id + 'Errors').html(text)
      this.setErrorBorder(id)
      $('#' + id).parent().addClass('has-error')
    },

    resetFieldError: function (id) {
      $('#' + id + 'Errors').text('')
      $('#' + id).removeClass('error')
      $('#' + id).parent().removeClass('has-error')
    },

    setErrorBorder: function (id) {
      $('#' + id).addClass('error')
    },

    clearErrorBorder: function (id) {
      $('#' + id).removeClass('error')
    },

    registerExpirationClearListener: function (monthTargetId, yearTargetId) {
      $('#' + monthTargetId + ',#' + yearTargetId).each(function () {
        $(this).focus(function () {
          this.resetFieldError(monthTargetId)
          this.resetFieldError(yearTargetId)
        })
      })
      $('#' + monthTargetId + ',#' + yearTargetId).each(function () {
        $(this).focus(function () {
          $('#' + monthTargetId + 'Errors').text('')
        })
      })
    },

    fieldNotNull: function (id) {
      if (this.elementExists('#' + id) && $('#' + id).val() === '') {
        this.changeFieldError(id, myApp.controllers.i16Strings.i16Strings.JSTextCodes_FIELD_NOT_COMPLETE)
        return false
      }
      return true
    },

    ExpirationDateInFuture: function (monthTargetId, yearTargetId) {
      if (this.elementExists('#' + monthTargetId) && this.elementExists('#' + yearTargetId)) {
        var expirationMonth = $('#' + monthTargetId).val()
        var expirationYear = $('#' + yearTargetId).val()
        var now = new Date()
        var currentYear = now.getFullYear()
        var currentMonth = now.getMonth() + 1
        if (expirationYear < currentYear || (currentYear === expirationYear && expirationMonth < currentMonth)) {
          this.changeFieldError(monthTargetId, myApp.controllers.i16Strings.i16Strings.JSTextCodes_CARD_EXPIRATION_IN_PAST)
          this.changeFieldError(yearTargetId)
          return false
        }
      }
      return true
    },

    validateCardNumber: function () {
      var valid = true
      valid = this.fieldNotNull('cardNumber') && valid
      if (!valid) {
        this.changeFieldError('cardNumber', myApp.controllers.i16Strings.i16Strings.JSTextCodes_FIELD_NOT_COMPLETE)
      } else if (!($('#cardNumber').val().match(/^[0-9]{12,19}$/))) {
        this.changeFieldError('cardNumber', myApp.controllers.i16Strings.i16Strings.UserCodes_CARD_NUMBER_INVALID)
        valid = false
      }
      return valid
    },

    validateSecurityCode: function () {
      var valid = true
      valid = this.fieldNotNull('securityCode') && valid
      if (!valid) {
        this.changeFieldError('securityCode', myApp.controllers.i16Strings.i16Strings.JSTextCodes_FIELD_NOT_COMPLETE)
      } else if (!($('#securityCode').val().match(/^[0-9]{3,4}$/))) {
        this.changeFieldError('securityCode', myApp.controllers.i16Strings.i16Strings.UserCodes_CARD_NUMBER_INVALID)
        valid = false
      }
      return valid
    }
  }
}
