PageApp.Views.TimedDetailsView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.timedDetails,
  serializeData: function () {
    var numberOrRef = myApp.ent.timedHelper.getNumberOrRef(this.model.attributes)
    var sashModel = myApp.ent.timedHelper.getSashModel(this.model.attributes)
    var showItemDetailsLink = (myApp.ent.config.attributes.page !== 'itemland') && this.model.attributes.itemDetailsAvailable
    var title = this.model.attributes.localeSensitive.title
    var summary = this.model.attributes.localeSensitive.summary

    var content = {
      loggedIn: myApp.ent.user.isLoggedIn(),
      numberOrRef: numberOrRef,
      contextPath: myApp.ent.config.get('contextPath'),
      showItemDetailsLink: showItemDetailsLink,
      sashModel: sashModel,
      title: title,
      summary: summary
    }
    content = _.extend(content, this.model.attributes)
    return content
  }
})
