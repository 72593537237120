// don't poll for the following stati
// 5 - WITHDRAWN
// 7 - SOLD_MAKE_OFFER
// 10 - SOLD_BUY_NOW
// 11 - SOLD_BULK_BUY_FULL
// 12 - SOLD_BULK_BUY_PARTIAL
// 14 - WITHDRAWN_ENDED
// 15 - TRANSFERRED
// 16 - TENDER_ACCEPTED
const ignoredItemStati = [5, 7, 10, 11, 12, 14, 15, 16]

PageApp.Ent.TimedItems = Backbone.Collection.extend({
  model: PageApp.Ent.TimedItem,
  initialise: function (options) {
  },
  defaults: {
  },
  getLongPollUrl: function () {
    if (!this.models || _.size(this.models) === 0) {
      return null
    }
    const itemIdMap = {}
    for (let i = 0; i < this.models.length; i++) {
      const item = this.models[i]
      if (ignoredItemStati.indexOf(item.attributes.timedBiddingInfo.statusId) === -1) {
        itemIdMap[item.attributes.id] = item.attributes.timedBiddingInfo.version
      }
    }
    if (Object.keys(itemIdMap).length) {
      return { 'itemIdMap': itemIdMap }
    }
    return null
  },
  getItemIds: function () {
    if (!this.models || _.size(this.models) === 0) {
      return null
    }
    let itemIds = []
    for (let i = 0; i < this.models.length; i++) {
      const item = this.models[i]
      if (ignoredItemStati.indexOf(item.attributes.timedBiddingInfo.statusId) === -1) {
        itemIds.push(item.attributes.id)
      }
    }
    return (itemIds.length) ? itemIds : null
  }
})
