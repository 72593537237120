PageApp.Views.ErrorNotFoundView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.notFound,
  serializeData: function () {
    var backToHomepageUrl = myApp.ent.webApp.attributes.webAppLogin.externalBaseUrl ? myApp.ent.webApp.attributes.webAppLogin.externalBaseUrl : '#home'
    var myContent = {
      JspPublicCodes_JSP_BACK_TO_HOMEPAGE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BACK_TO_HOMEPAGE'),
      backToHomepageUrl: backToHomepageUrl
    }
    return _.extend(this.model.attributes, myContent)
  }
})
