PageApp.Views.ModalLayout = Backbone.Marionette.LayoutView.extend({
  template: hbs.templates.modalLayout,
  events: {
    'click .x-close-modal': 'handleClose',
    'click .x-cancel': 'handleClose',
    'click .x-login--link': 'handleLink',
    'keyup .x-add-commas ': 'handleAddCommasOnKeyup',
    'keyup .x-numeric-only ': 'handleNumericOnlyOnKeyup',
    'keyup .x-decimal-add-commas': 'handleDecimalAddCommasOnKeyup'
  },
  regions: {
    myModal: '#myModal'
  },
  onRender: function () {
    this.myModal.show(this.options.view)
    this.myModal.$el.on('hide.bs.modal', _.bind(function (e) {
      this.myModal.empty()
    }, this))
    this.myModal.$el.on('hidden.bs.modal', function (e) {
      myApp.vent.trigger('modal:closed')
    })
    this.myModal.$el.modal('show')
    $('body').css('padding-right', '0px')
  },
  handleAddCommasOnKeyup: function (e) {
    e.preventDefault()
    var $input = $(e.currentTarget)
    if (e.keyCode !== 13) {
      $input.val(myApp.utils.types.toFormattedNumberNoDecimal($input.val(), myApp.ent.user.attributes.thousandsSeparator))
    }
    return false
  },
  handleDecimalAddCommasOnKeyup: function (e) {
    e.preventDefault()
    var $input = $(e.currentTarget)
    if (e.keyCode !== 13) {
      $input.val(myApp.utils.types.toFormattedNumber($input.val(), myApp.ent.user.attributes.thousandsSeparator))
    }
    return false
  },
  handleNumericOnlyOnKeyup: function (e) {
    e.preventDefault()
    var $input = $(e.currentTarget)
    if (e.keyCode !== 13) {
      $input.val(myApp.utils.types.toNumericCharsOnlyNoDecimal($input.val(), myApp.ent.user.attributes.thousandsSeparator))
    }
    return false
  },
  handleLink: function () {
    this.closeModal()
  },
  handleClose: function (e) {
    if (e) e.preventDefault()
    this.closeModal()
  },
  closeModal: function () {
    this.myModal.empty()
    this.myModal.$el.modal('hide')
  },
  remove: function () {
    // @TECHDEBT
    // this is a hack in order to handle the removal of the modal backdrop on client-side navigation
    // a better way of handling this
    $('body').removeClass('modal-open')
    $('.modal-backdrop').remove()
    Backbone.View.prototype.remove.apply(this, arguments)
  }
})
