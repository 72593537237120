PageApp.Views.CollectionContainerLayout = Backbone.Marionette.LayoutView.extend({
  template: hbs.templates.layoutControlLayout,
  regions: {
    collectionRegion: '#collectionRegion'
  },
  onRender: function () {
    this.collectionRegion.on('show', function (view) {
      myApp.vent.trigger('application:rendered', { region: myApp.mainRegion })
    })
    if (_.size(this.model.models) === 0) {
      this.collectionRegion.show(new PageApp.Views.NoItemsFoundView())
    } else {
      this.collectionRegion.show(new PageApp.Views.TimedItemsView({ collection: this.model }))
    }
  }
})
