PageApp.Views.AlertView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.alert,
  serializeData: function () {
    var message = null
    var type = 'info'
    var showLoader = false
    if (this.model && this.model.get) {
      message = this.model.get('content')
      showLoader = this.model.get('showLoader')
      type = this.model.get('type')
    }
    return {
      message: message,
      showLoader: showLoader,
      type: type
    }
  }
})
