PageApp.Controllers.SaleInfoRouter = function (config) {
  function initEvents () {
    myApp.vent.on('saleinfo:display', function (config) {
      if (!this.modalInProgress) {
        var model = (config && config.saleInfoModel) ? config.saleInfoModel : myApp.ent.saleInfo
        var view = new PageApp.Views.SaleInfoView({ model: model, modal: true })
        myApp.myModalRegion.show(new PageApp.Views.ModalLayout({ view: view }))
        this.modalInProgress = true
      }
    }, this)
    myApp.vent.on('modal:closed', function (config) {
      this.modalInProgress = false
    }, this)
  }
  return {
    initialize: function (models) {
      initEvents()
    }
  }
}
