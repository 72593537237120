PageApp.Views.LandingBannerView = Backbone.Marionette.ItemView.extend({
  getTemplate: function () {
    return hbs.templates.landingBanner
  },
  serializeData: function () {
    var hclass, available
    var totalNumberOfMatches = this.model.get('totalNumberOfMatches')
    if (this.model.get('bannerType') === 'auction') {
      hclass = 'auction-header'
      if (totalNumberOfMatches === 1) {
        available = totalNumberOfMatches + ' ' + myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_ITEM_AVAILABLE')
      } else {
        available = totalNumberOfMatches + ' ' + myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION_ITEMS_AVAILABLE')
      }
    } else if (this.model.get('bannerType') === 'marketplace') {
      hclass = 'marketplace-header'
    } else if (this.model.get('bannerType') === 'search') {
      hclass = 'hidden'
    }
    var content = {
      message: this.model.get('title'),
      hclass: hclass,
      available: available,
      description: this.model.get('auctionDescription'),
      endDate: this.model.get('auctionEndDate'),
      webcast: this.model.get('webcast'),
      startDate: this.model.get('auctionStartDate'),
      startTime: this.model.get('auctionStartTime'),
      endTime: this.model.get('auctionEndTime')
    }
    return content
  }
})
