PageApp.Ent.ReferenceData = Backbone.Model.extend({
  initialise: function (options) {
  },
  defaults: {
    'errors': {}
  },
  getKeysNeeded: function (types) {
    var typesSplit = types.split(',')
    var toFetch = []

    for (var key in typesSplit) {
      var ref = typesSplit[key].trim()
      if (!this.get(ref)) {
        toFetch.push(ref.trim())
      }
    }
    if (toFetch.length > 0) {
      return toFetch
    }
  },
  getReferenceData: function (types) {
    var toFetch = {}
    var splitTypes = types.split(',')
    for (var key in splitTypes) {
      var ref = splitTypes[key].trim()
      if (this.get(ref)) {
        toFetch[ref] = this.get(ref)
      }
    }
    return toFetch
  },
  setReferenceData: function (types, referenceData) {
    var splitTypes = types.split(',')
    for (var key in splitTypes) {
      var ref = splitTypes[key]
      this.set(ref, referenceData[ref])
    }
  }

})
