PageApp.Ent.SearchFilters = Backbone.Model.extend({
  defaults: {
  },
  supportsSearch: function () {
    return myApp.ent.config.get('page') === 'searchland'
  },
  supportsBanner: function () {
    return myApp.ent.config.get('page') === 'marketland' || myApp.ent.config.get('page') === 'auctionland'
  },
  supportsPaging: function () {
    return (this.get('pagingModel').numPages > 1)
  },
  findItem: function (filterItems, selectedId) {
    for (var key in filterItems) {
      var objParent = filterItems[key]
      if (objParent.item.id === selectedId) return objParent.item
      for (var key1 in objParent.childItems) {
        var objChild = objParent.childItems[key1]
        if (objChild.id === selectedId) return objChild
      }
    }
  },
  getLink: function (paramName, paramValue) {
    // split the url into the hashed path, and the parameters
    var params = window.location.hash.split('?')
    // set the hashed path as our base
    var link = params[0]

    // if we have parameters
    // otherwise return unchanged hashed path
    if (params.length === 2 && params[1] && params[1].length > 0) {
      // parse the parameters into an object for ease of manipulation
      var paramsAsObject = JSON.parse('{"' + decodeURI(params[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
      // delete any page parameter if it exists, to send the user back to the first page
      delete paramsAsObject.page
      // add the specified param and append to the link
      paramsAsObject[paramName] = paramValue
      link += '?' + decodeURIComponent($.param(paramsAsObject))
    } else {
      // add the param to the base link
      link += '?' + paramName + '=' + paramValue
    }
    return link
  },
  getRemoveLink: function (filterId) {
    // split the url into the hashed path, and the parameters
    var params = window.location.hash.split('?')
    // set the hashed path as our base
    var link = params[0]

    // if we have parameters, and we don't want to filter as all
    // otherwise return unchanged hashed path
    if (params.length === 2 && filterId !== 'all') {
      if (params[1].length === 0) {
        return
      }
      // parse the parameters into an object for ease of manipulation
      var paramsAsObject = JSON.parse('{"' + decodeURI(params[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
      switch (filterId) {
        case 'tree':
          delete paramsAsObject.category
          delete paramsAsObject.subCategory
          break
        case 'category':
          delete paramsAsObject.subCategory
      }
      // remove the parameter specified in the filterId
      delete paramsAsObject[filterId]
      // delete any page parameter if it exists, to send the user back to the first page
      delete paramsAsObject.page
      // convert back to query parameters
      var paramsAsString = decodeURIComponent($.param(paramsAsObject))
      // only append the query parameters if we have params remaining
      if (paramsAsString.length > 0) {
        link += '?' + paramsAsString
      }
    }
    return link
  }
})
