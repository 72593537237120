PageApp.WebrtcUtils = function () {
  var session
  var publisher
  var subscriber
  var connectionCount = 0
  var stream
  var targetEl = 'tokvid'

  return {
    startSubscribing: function (feedModel, callback) {
      if (!stream) { return }
      var options
      if (feedModel.options) {
        options = feedModel.options
      } else {
        var feedHeight = feedModel.feedType === 'AUDIO' ? 100 : 450
        options = {
          width: '100%', height: feedHeight, insertMode: 'replace'
        }
      }
      subscriber = session.subscribe(stream, targetEl, options, function (error) {
        if (error && console) {
          console.log('subscribed: error=', error)
        }
      })
    },

    startPublishing: function (feedModel, callback) {
      var publishVideo
      if (feedModel.feedType === 'AUDIO') {
        publishVideo = false
      } else {
        publishVideo = true
      }
      var options = {
        width: 135, height: 100, insertMode: 'append', publishVideo: publishVideo
      }
      if (!session) {
        throw new Error('no session!')
      }
      publisher = OT.initPublisher(targetEl, options)
      session.publish(publisher)
      publisher.on('streamCreated', function (event) {
        if (event.stream) { stream = event.stream }
        if (event) {
          console.log('streamCreated: event: ', event)
        }
        if (callback) { callback() }
      })
    },
    toggleAudio: function (feedModel) {
      if (!subscriber) {
        return
      }
      var currentAudioLevel = subscriber.getAudioVolume()
      if (currentAudioLevel === 0) {
        subscriber.setAudioVolume(100)
      } else {
        subscriber.setAudioVolume(0)
      }
    },
    stopPublishing: function (feedModel) {
      if (publisher) {
        session.unpublish(publisher)
        stream = undefined
      }
    },
    stopSubscribing: function (feedModel) {
      if (subscriber) { session.unsubscribe(subscriber) }
      subscriber = undefined
    },
    clearSession: function () {
      session = undefined
    },
    getConnectionCount: function () {
      return connectionCount
    },
    isPublishing: function () {
      if (publisher && stream) {
        return true
      }
      return false
    },

    isStream: function () {
      if (stream) {
        return true
      }
      return false
    },

    // We need to trigger events in both the public and admin apps so require the eventBus parameter.
    joinSession: function (feedModel, eventBus, callback) {
      if (!feedModel) {
        return
      }
      var apiKey = feedModel.tokApiKey
      var sessionId = feedModel.tokSessionId
      var token = feedModel.tokToken
      if (!feedModel.feedProviderId || !apiKey || !sessionId || !token) {
        return
      }

      // If we are using an iOS app then don't even check to see whether tokbox is supported.
      // This is to prevent any tokbox generated messages from being displayed.
      // The event webrtc:stream:notSupported is also not triggered which prevents our
      // error message from being displayed also.
      if (this.isIOSApp()) {
        return
      }

      if (OT.checkSystemRequirements() === 1) {
        session = OT.initSession(apiKey, sessionId)
      } else {
        if (eventBus)eventBus.trigger('webrtc:stream:notSupported')
        OT.upgradeSystemRequirements()
        return
      }

      session.on('streamDestroyed', function (event) {
        if (subscriber) {
          session.unsubscribe(subscriber)
          stream = undefined
          subscriber = undefined
          if (eventBus) eventBus.trigger('webrtc:stream:destroyed')
        }
        if (console.log) { console.log('Stream destroyed') }
      })
      session.on('streamCreated', function (event) {
        stream = event.stream
        if (eventBus) {
          eventBus.trigger('webrtc:stream:created')
        }
        if (console.log) { console.log('Stream created') }
      })
      session.connect(token, function (error) {
        if (error && console) {
          console.log('There was an error connecting to the session:', error.code, error.message)
        } else {
          if (eventBus) {
            eventBus.trigger('webrtc:stream:connected')
          }
        }
      })
    },
    isIOSApp: function () {
      var isIOSApp = false
      var standalone = window.navigator.standalone
      var userAgent = window.navigator.userAgent.toLowerCase()
      var safari = /safari/.test(userAgent)
      var ios = /iphone|ipod|ipad/.test(userAgent)

      if (ios && !standalone && !safari) {
        isIOSApp = true // uiwebview
      }
      return isIOSApp
    }
  }
}
