PageApp.Views.SearchInputView = Backbone.Marionette.ItemView.extend({
  template: null,
  initialize: function () {
    this.template = hbs.templates.searchInput
  },
  serializeData: function () {
    var content = {
      multiTreeAuctioneer: myApp.ent.webApp.attributes.multiTreeAuctioneer,
      categoryTrees: myApp.ent.webApp.attributes.categoryTrees,
      JspPublicCodes_JSP_HEADER_SEARCH_ITEMS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_HEADER_SEARCH_ITEMS'),
      JspPublicCodes_JSP_LABEL_ALL_CATEGORIES: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LABEL_ALL_CATEGORIES')
    }
    return content
  },
  events: {
    'click #search-submit': 'handleSearchForItems'
  },
  onShow: function () {
    if (this.model) {
      var query = this.model.get('currentFilterModel').queryFilterName
      if (query && query.length > 0) {
        this.$el.find('.x-query-input').val(query)
      }
      var treeId = this.model.get('currentFilterModel').categoryTreeId
      if (treeId && treeId > 0) {
        this.$el.find('.x-tree-input option[value=' + treeId + ']').prop('selected', true)
      }
    }
  },
  handleSearchForItems: function (e) {
    e.preventDefault()
    var treeId = this.$el.find('#categoryTree').val()
    var query = this.$el.find('#searchFilter').val()
    var action = this.$el.find('#searchForItems').attr('action')
    var url = myApp.ent.config.attributes.contextPath + '/' + action + '?query=' + query + '&tree=' + treeId
    myApp.utils.ajax.relocate(url)
    return false
  }
})
