PageApp.Views.CatalogueView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.catalogue,
  serializeData: function () {
    var hasExternalWebpageUrl = (this.model.attributes.fullExternalWebpageUrl && this.model.attributes.fullExternalWebpageUrl.length > 0)
    var content = {
      JspPublicCodes_JSP_CATALOGUE_ITEM: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CATALOGUE_ITEM'),
      JspPublicCodes_JSP_CATALOGUE_GOTO_EXTERNAL_WEBSITE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CATALOGUE_GOTO_EXTERNAL_WEBSITE'),
      contextPath: myApp.ent.config.get('contextPath'),
      hasExternalWebpageUrl: hasExternalWebpageUrl
    }
    content = _.extend(content, this.model.attributes)
    return content
  }
})
