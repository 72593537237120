PageApp.Controllers.AddressRouter = function () {
  function initEvents () {
    myApp.vent.on('address:display', function (config) {
      new PageApp.Views.WaitingView({ el: config.region }).render()
      var contentPromise = myApp.request('reqres:reference:fetch', { types: 'countries' })
      contentPromise.done(_.bind(function (result) {
        var model = new PageApp.Ent.Address(config.model)
        model.set('countries', result.countries)
        var whichAddress = model.get('whichAddress')
        if (whichAddress === 0) {
          if (myApp.ent.user.get('hasMainAddress')) {
            model.set('address', myApp.ent.user.get('mainAddress'))
          }
        } else if (whichAddress === 1) {
          if (myApp.ent.user.get('hasShippingAddress')) {
            model.set('address', myApp.ent.user.get('shippingAddress'))
          }
        }
        new PageApp.Views.AddressView({ el: config.region, model: model }).render()
      }), this)
    }, this)
  }
  return {
    initialize: function (models) {
      initEvents()
    }
  }
}
