PageApp.Views.TimedItemsView = Marionette.CollectionView.extend({
  childView: PageApp.Views.TimedItemLayout,
  initialize: function (options) {
    this.collection = options.collection
  },
  events: {
  },
  className: function () {
    var layout = myApp.ent.user.getPreference('layoutForBidding', { value: 'grid' })
    return (layout === 'grid') ? 'grid-container' : 'list-container'
  },
  childViewOptions: function (model, index) {
    return {
      childIndex: index,
      hasConnection: this.hasConnection
    }
  }
})
