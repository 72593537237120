PageApp.ErrorUtils = function () {
  var ImgErrorWithCss = function (img, cssClass) {
    try {
      var $img = $(img)
      var width = $img.data('width')
      var height = $img.data('height')
      img.onerror = null
      var parentNode = img.parentNode
      if (parentNode) {
        var imgSrc = img.src.replace('?', '')
        var isAwaitingImg = (imgSrc.length === 0 || window.location.href.indexOf(imgSrc) === 0)
        var parentClassName = parentNode.className
        parentNode.className = parentClassName.length > 0 ? parentClassName + ' ' + cssClass : cssClass
        if (width) $(parentNode).css('width', width + 'px')
        if (height) $(parentNode).css('height', height + 'px')
        if (isAwaitingImg) {
          parentNode.innerHTML = '<div class="img-error-container"><div><!--' + img.src + '-->' + myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AWAITING_IMAGE') + '</div></div>'
        } else {
          parentNode.innerHTML = '<div class="img-error-container"><div><!--' + img.src + '-->' + myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MISSING_IMAGE') + '</div></div>'
        }
      }
      return false
    } catch (err) {
      console.log(err)
    }
  }

  return {

    ImgErrorThumb: function (img) {
      return ImgErrorWithCss(img, 'img-error-thumb')
    },
    ImgErrorPreview: function (img) {
      return ImgErrorWithCss(img, 'img-error-preview')
    },
    ImgError: function (img) {
      return ImgErrorWithCss(img, 'img-error-thumb')
    },
    ImgErrorHomeList: function (img) {
      return ImgErrorWithCss(img, 'img-error-list-home')
    },
    ImgErrorWebSaleInfo: function (img) {
      return ImgErrorWithCss(img, 'img-error-saleinfo-web')
    },
    ImgErrorHomeGallery: function (img) {
      return ImgErrorWithCss(img, 'img-error-gal-home')
    },
    ImgErrorHomeAuctioneer: function (img) {
      return ImgErrorWithCss(img, 'img-error-auctioneer-home')
    },
    ImgErrorHomeAuctioneerLg: function (img) {
      return ImgErrorWithCss(img, 'img-error-auctioneer-home-lg')
    }
  }
}
