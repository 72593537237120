PageApp.Views.CategoryTreesFilterView = Backbone.Marionette.ItemView.extend({
  getTemplate: function () {
    return hbs.templates.categoryTreesFilter
  },
  initialize: function (options) {
    this.options = options
  },
  events: {
    'click .x-category': 'handleCategory',
    'click .x-more': 'handleMore',
    'click .x-less': 'handleLess'
  },
  serializeData: function () {
    var myModel = this.model.attributes.categoryFilterModel
    var moreorless = 'more'
    var hMarketClass
    var overflowLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_FILTER_MORE')
    var OVERFLOW_BOUNDARY = 4 // not const as not available in IE8

    // Sort the trees
    myModel.trees.sort(this.categoryCompare)
    for (var key in myModel.trees) {
      var thisTree = myModel.trees[key]
      var childItems = thisTree.childItems

      // Sort the categories
      // childItems.sort(function(a,b) {return (a.item.name > b.item.name) ? 1 : ((b.item.name > a.item.name) ? -1 : 0);} );
      childItems.sort(this.categoryCompare)

      // IE8 doesn't support child items. So if undefined set numberOfChildItems to 1 more than overflow boundary.
      // Should hopefully mean all categories can be displayed though more/less labels may display unnecessarily.
      var numberOfChildItems = thisTree.childItems ? thisTree.childItems.length : OVERFLOW_BOUNDARY + 1
      thisTree.overflow = (numberOfChildItems > OVERFLOW_BOUNDARY)
      var count = 0
      for (var key1 in thisTree.childItems) {
        var parent = thisTree.childItems[key1]
        parent.childItems.sort(this.categoryCompare)
        parent.display = false
        if (thisTree.more || count < 5) parent.display = true
        count++
      }
      if (thisTree.more) {
        moreorless = 'less'
        overflowLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_FILTER_LESS')
      }
    }
    if (myApp.ent.config.get('page') === 'marketland') {
      hMarketClass = 'g2-market-title-bg'
    }
    var content = {
      hMarketClass: hMarketClass,
      filterHeaderName: myModel.filterTitle,
      trees: myModel.trees,
      queryParameterName: 'category',
      childQueryParameterName: 'subCategory',
      moreorless: moreorless,
      overflowLabel: overflowLabel
    }
    return content
  },
  categoryCompare: function (a, b) {
    return (a.item.name > b.item.name) ? 1 : ((b.item.name > a.item.name) ? -1 : 0)
  },
  handleCategory: function (e) {
    if (e) e.preventDefault()
    var itemId = $(e.currentTarget).data('itemid')
    var level = $(e.currentTarget).data('level')
    var link = this.model.getLink(level, itemId)
    myApp.utils.ajax.relocate(link)
  },
  handleMore: function (e) {
    if (e) e.preventDefault()
    var selectedId = $(e.currentTarget).data('treeid')
    var myModel = this.model.attributes.categoryFilterModel
    for (var key in myModel.trees) {
      if (myModel.trees[key].item.id === selectedId) {
        myModel.trees[key].more = true
      }
    }
    this.render()
  },
  handleLess: function (e) {
    if (e) e.preventDefault()
    var selectedId = $(e.currentTarget).data('treeid')
    var myModel = this.model.attributes.categoryFilterModel
    for (var key in myModel.trees) {
      if (myModel.trees[key].item.id === selectedId) {
        myModel.trees[key].more = false
      }
    }
    this.render()
  }
})
